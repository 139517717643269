import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import BootstrapInput from '../FormInput/BootstrapInput2';
import AsyncSelect from '../Select/AsyncSelect';
import axios from '../../helpers/axiosWebEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*
    Component used on Web Entry create/update form to set associated events views.
    Props:
    - name: Name of parent component. This component is nested in parent so inner input names must be named like parent[0].inputname
    - onChange: callback called on change of inputs
    - onRemove: callback called on removing of this component
    - value: object storing all input values of this component providing it to parent
    - error: objects storing errors of whole form including parent; used for displaying errors
*/
export default class extends React.Component {

    /**
     * Ajax load of views by name for Select component of this form.
     */
    getViews = (query) => {
        return axios.get('/eventlistview', {params:{name:query}}).then(response=>{
            if (response && response.data){
                const tmp = response.data.map((el, index)=>({value:el.id, label:el.name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }

    /**
     * Ajax load of DataEntries for Select component of this form.
     */
    getEntries = (query) => {
        return axios.get('/eventtypepage', {params:{name:query}}).then(response=>{
            if (response && response.data){
                const tmp = response.data.map((el, index)=>({value:el.id, label:el.name}));
                return tmp;
            }
            return [];
        }).catch(error=>console.log(error));
    }

    render(){
        const {name, onChange, onRemove, value, error} = this.props;
        return(
            <Card>
                <Card.Body>
                    <Row>
                        <Col><BootstrapInput name={name + '.Label'} label="Associated Events Label" value={value.Label} error={error} onChange={onChange}/></Col>
                        <Col><BootstrapInput name={name + '.EventsDataView'} loadOptions={this.getViews} label="Data View" value={value.EventsDataView} error={error} component={AsyncSelect} onChange={onChange}/></Col>
                        {onRemove ? <FontAwesomeIcon onClick={onRemove} className="remove-icon" icon="times"/> : null}
                    </Row>
                    <Row>
                        <Col><BootstrapInput name={name + '.UrlParams'} value={value.UrlParams} onChange={onChange} label="URL Params"/></Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}
