import React from 'react';
import withContext from '../../hoc/withContext';

const NoTilesMessage = props => (
    props.hidemessage
        ? null
        : <React.Fragment>
            {props.context.user
                ? <div className="no_tiles_message">{process.env.REACT_APP_NO_ROLES_MESSAGE}</div>
                : <div className="no_tiles_message">{process.env.REACT_APP_NOT_LOGGED_MESSAGE}</div>
            }
        </React.Fragment>
);

export default withContext(NoTilesMessage);