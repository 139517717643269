import React from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import Error from '../FieldError/FieldError';
import {get} from 'lodash';

/**
 * Form input component used on every form. It uses react-boostrap lib. It handles displayng or errors too.
 * Props:
 * label: input label
 * disableLabel: hide input label
 * info: small hint text under input
 * component: inner component like input, datepicker, select etc
 * prepend: content to prepend like bootstrap input icons
 * append: content to append like bootstrap input icons
 * name: field name
 * error: object holding errors of whole form
 */
export default class extends React.Component {
    /**
     * Changed rerender logic to prevent unecessary renders.
     */
    shouldComponentUpdate=(nextProps)=>{
        const currError = get(this.props.error, this.props.name);
        const nextError = get(nextProps.error, nextProps.name);
        return this.props.value !==nextProps.value || this.props.name !==nextProps.name ||
            (currError !== undefined && nextError === undefined) ||
            (currError === undefined && nextError !== undefined) ||
            (currError !== nextError) || (this.props.visible !== nextProps.visible) ||
            (this.props.options !== nextProps.options)  //to properly handle enum options visibility show/hide
    }

    render(){
        const {disableLabel, component: Component, prepend:Prepend, append: Append, visible, ...rest} = this.props;
        let error = undefined;
        if (this.props.error)
            error = get(this.props.error, this.props.name);

        const style = visible === 0 ? { display: 'none' } : { display: 'block' };

        return (
            <Form.Group style={style}>
                <React.Fragment>
                    {disableLabel ? null : <Form.Label>{this.props.label}</Form.Label>}
                    {Prepend||Append? (
                        <InputGroup>
                        {Prepend && (
                            <InputGroup.Prepend>
                                <Prepend/>
                            </InputGroup.Prepend>
                        )}
                        {Component ? (<Component {...rest}/>) : (<Form.Control {...rest}/>)}
                        {Append && (
                            <InputGroup.Append>
                                <Append/>
                            </InputGroup.Append>
                        )}
                    </InputGroup>
                    ) : (
                        Component ? (<Component {...rest}/>) : (<Form.Control {...rest}/>)
                    )}
                    {error && <Error>{error}</Error>}
                </React.Fragment> 
                {this.props.info ? <Form.Text className="text-muted">{this.props.info}</Form.Text> :null}
            </Form.Group>
        );
    }
    
};