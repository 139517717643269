import React from 'react';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {Form, InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {get_locale} from '../../helpers/utils';

/**
 * Modified datepicker with calendar icon on right.
 */
const Input = props=>(
    <InputGroup>
        <Form.Control {...props} readOnly={true}/>
        <InputGroup.Append>
            <InputGroup.Text><FontAwesomeIcon icon="calendar"/></InputGroup.Text>
        </InputGroup.Append>
    </InputGroup>
);

/**
 * Datepicker component.
 * Props:
 * name: name of input
 * value: value of input providing connection to parent
 * onChange: callback called on every input change
 */
export default props=>{
    const {name, value, onChange, ...rest} = props;
    return(

        <Datepicker
            {...rest}
            customInput={<Input/>}
            onChange={(val)=>{
                onChange({target:{name:name, value:val}});
            }}
            selected={value}
            showTimeInput
            dateFormat={'P p'}
            showYearDropdown
            showMonthDropdown
            dropdownMode='select'
            dateFormatCalendar="MMMM"            
            locale={get_locale()}
        />
    );
}
    