import React from 'react';
import Group from './DataEntryFormGroup';

/**
 * Component wrapping groups on DataEntry create/update form for better handling of rerendering.
 */
class Groups extends React.Component{
    /*shouldComponentUpdate=(nextProps)=>{
        return this.props.groups !== nextProps.groups;
    }*/

    render(){
        return(
            this.props.groups.map((g,i)=>(
                <Group key={i} name={`Groups[${i}]`} index={i} value={g} error={this.props.error} onChange={this.props.onChange} onRemove={this.props.onRemove}/>                    
            ))
        );
    }
}

export default Groups;