import React from 'react';
import * as yup from 'yup';
import { Button, Container } from 'react-bootstrap';
import { setOktaConfig, OktaAuthObject, setAadConfig, AdalAuthObject } from '../../helpers/adalConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons'

import BootstrapInput from '../../components/FormInput/BootstrapInput2';
import withContext from '../../hoc/withContext';
import { withRouter } from 'react-router-dom';
//import { withOktaAuth } from '@okta/okta-react';
//import OktaLoginButton from './OktaLoginButton';
import axiosUser from '../../helpers/axiosUser';
//import { OktaAuth } from '@okta/okta-auth-js';



const schemaForLogin = yup.object().shape({
    email: yup.string().required('Required.'),
    password: yup.string().required('Required.')
});

const schemaForProviders = yup.object().shape({
    email: yup.string().required('Required.'),
});

//const { authState, oktaAuth } = useOktaAuth();

//const login = async () => oktaAuth.signInWithRedirect();
//const logout = async () => oktaAuth.signOut();

class Login extends React.Component {
    state = {
        data: {
            email: '',
            password: ''
        },
        error: {},
        providers: [],
        providersRequestSent: false,
        showPasswordInput: false,
        noProviders: false
    }

    //tempProviders = [
    //    {
    //        ID: 1,
    //        Name: "Native",
    //        SecurityProviderTypeId: 0,
    //        ClientId: undefined,
    //        TenantDomain: undefined,
    //        TenantId: undefined,
    //        NameClaimType: "unique_name"
    //    },
    //    {
    //        ID: 2,
    //        Name: "Azure AD",
    //        SecurityProviderTypeId: 1,
    //        ClientId: "a8f1ce60-4b63-46f1-afbf-3b8ec1122253",
    //        TenantDomain: "iconics-uk.com",
    //        TenantId: "b1385e84-5062-4882-a3e6-fc7e229a1d5d",
    //        NameClaimType: "unique_name"
    //    },
    //    {
    //        ID: 3,
    //        Name: "Okta",
    //        SecurityProviderTypeId: 2,
    //        ClientId: "0oabu3s5bPd31TQx65d6",
    //        TenantDomain: "dev-76852820.okta.com",
    //        NameClaimType: "email"
    //    },
    //    {
    //        ID: 3,
    //        Name: "Fake Okta",
    //        SecurityProviderTypeId: 2,
    //        ClientId: "0oabufds3s5bPd31T4564Qx65d6",
    //        TenantDomain: "dev-71479685468792820.okta.com",
    //        NameClaimType: "email"
    //    }
    //];

    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...this.state.data };
        const email = data.email;
        const error = { ...this.state.error }
        data[name] = value;
        error[name] = undefined;
        const requestSentTemp = data.email !== email ? false : this.state.providersRequestSent;
        const providers = requestSentTemp ? this.state.providers : [];
        const showPass = requestSentTemp ? this.state.showPasswordInput : false;
        this.setState({ data: data, error: error, providersRequestSent: requestSentTemp, providers: providers, showPasswordInput: showPass });
    }

    componentDidMount = () => {
        this.props.context.setHeading('Login');
    }

    componentWillUpdate = () => {
        const { match: { params } } = this.props;
        if (this.props.context.user) {
            this.props.history.push(params.returnUrl ? decodeURIComponent(params.returnUrl) : '/');
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        
        if (this.state.providersRequestSent === false)  // this is only when the request for providers has not been sent yet
            this.loadProviders();
        else {  //this is when we already have provider
            schemaForLogin.validate(this.state.data, { abortEarly: false }).then(values => {
                this.props.context.login(this.state.data.email, this.state.data.password);
            }).catch(errors => {
                console.log(errors);
                const error = {};
                errors.inner.forEach(el => {
                    error[el.path] = el.message;
                });
                this.setState({ error: error });
            });
        }
    };

    //adal = () => {
    //    AuthContext.login();
    //}

    //oktaLogin = () => {
    //    AuthOktaContext.signInWithRedirect(window.location.origin);
    //}

    loadProviders = () => {
        schemaForProviders.validate(this.state.data, { abortEarly: false }).then(values => {
            var username = this.state.data.email;

            //this.setState({ providers: this.tempProviders, providersRequestSent: true });

            //disable button / show spinner
            axiosUser.get(username + '/identityproviders').then(response => {
                if (response && response.data) {
                    var providers = response.data;

                    //no providers
                    if (providers.length === 0)
                        this.setUserNotFound();
                    //one providers, login directly
                    else if (providers.length === 1) {
                        var singleProvider = providers[0];
                        this.setState({ providersRequestSent: true });
                        if (singleProvider.SecurityProviderTypeId === 0) {
                            this.loginViaNative();
                        }
                        else {
                            this.loginViaProvider(singleProvider);
                        }

                    }
                    //multiple providers, show buttons
                    else {
                        this.setState({ providers: providers, providersRequestSent: true });
                    }
                }
                else {
                    this.setUserNotFound();
                }
            }).catch(error => {
                //enable button / hide spinner
                //tell something to user
                console.log(error)
                this.setState({ providersRequestSent: true });
            });

        }).catch(errors => {
            console.log(errors);
            const error = {};
            errors.inner.forEach(el => {
                error[el.path] = el.message;
            });
            this.setState({ error: error });
        });
    }


    setUserNotFound = () => {
        var error = { ...this.state.error }
        error["email"] = "User not found.";
        this.setState({ error })
    }


    loginViaProvider = (provider) => {
        if (provider.SecurityProviderTypeId === 1) {    //aad
            setAadConfig(provider)
            localStorage.setItem("aadProvider", JSON.stringify(provider))
            AdalAuthObject.login();
        }
        else if (provider.SecurityProviderTypeId === 2) {   //okta
            setOktaConfig(provider);
            localStorage.setItem("oktaProvider", JSON.stringify(provider))
            OktaAuthObject.signInWithRedirect(window.location.origin);
        }
    }

    loginViaNative = () => {
        this.setState({ showPasswordInput: true })
    }


    //clearProviders = () => {
    //    this.setState({ providers: [], providersRequestSent: false });
    //}

    render() {
        return (
            <Container className="content-overlay login-container">
                <form className="flex-column" onSubmit={this.handleSubmit}>
                    <BootstrapInput name="email" label="Email" onChange={this.onChange} error={this.state.error} autoFocus />

                    {this.state.providersRequestSent ?
                        /*<button type="button" onClick={this.clearProviders} className="btn btn-block btn-success">Back</button>*/
                        ""
                        :
                        <button type="button" onClick={this.loadProviders} className="btn btn-block btn-primary">Next</button>
                    }

                    {this.state.noProviders ?
                        <div style={{ backgroundColor: 'red', color: 'white', width: '100%', padding: '3px', borderRadius: '0.25rem' }}>User not found.</div>
                        :
                        ""
                    }


                    {this.state.providers.sort((a, b) => b.SecurityProviderTypeId - a.SecurityProviderTypeId).map((provider) => {

                        if (provider.SecurityProviderTypeId === 0)  //native
                            return (<button type="button" onClick={this.loginViaNative} className="btn btn-block btn-success">Login with {provider.Name}&nbsp;<FontAwesomeIcon icon={faSignInAlt} /></button>)
                        else if (provider.SecurityProviderTypeId === 1) //AAD
                            return (<button type="button" onClick={() => this.loginViaProvider(provider)} className="btn btn-block btn-success">Login with {provider.Name}&nbsp;<FontAwesomeIcon icon={faMicrosoft} /></button>)
                        else if (provider.SecurityProviderTypeId === 2) //Okta
                            return (<button type="button" onClick={() => this.loginViaProvider(provider)} className="btn btn-block btn-success">Login with {provider.Name}&nbsp;<FontAwesomeIcon icon={faCircle} /></button>)
                    })}

                    {/*<button type="button" onClick={this.loadProviders} className="btn btn-block btn-primary">Next</button>*/}

                    {/*<button type="button" onClick={this.adal} className="btn btn-block btn-link">Login with Microsoft&nbsp;<FontAwesomeIcon icon={['fab', 'microsoft']} /></button>*/}

                    {this.state.showPasswordInput ?
                        <React.Fragment>
                            <BootstrapInput name="password" label="Password" type="password" onChange={this.onChange} error={this.state.error} />
                            <input type="submit" onClick={this.handleSubmit} className="btn btn-block btn-success" value="Login" />
                        </React.Fragment>
                        :
                        ""
                    }

                    {/*<button type="button" onClick={this.adal} className="btn btn-block btn-link">Login with Microsoft&nbsp;<FontAwesomeIcon icon={['fab', 'microsoft']} /></button>*/}
                    {/*<button type="button" onClick={this.oktaLogin} className="btn btn-block btn-link">Login with Okta</button>*/}
                    {/*<OktaLoginButton />*/}
                </form>
            </Container>
        );
    }
}



export default withRouter(withContext(Login));