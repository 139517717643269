import React from 'react';
import ReactTable from 'react-table';
import axiosData from '../../helpers/axiosData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, ButtonGroup} from 'react-bootstrap';
import withContext from '../../hoc/withContext';

/**
 * Table to display office exports for event.
 * Props:
 * -nodeid
 * -eventId
 */
class List extends React.Component{

    state={
        //row/files for table
        rows:[],
        //table load spinner visibility
        isLoading: true
    }

    componentDidMount() {
        this.loadRows();
    }

    /**
     * Load files/row for table form API.
     */
    loadRows = () => {
        this.setState({isLoading: true});
        axiosData.get(`/v1/OfficeExport/${this.props.nodeId}/${this.props.eventId}`)
        .then(response => {
            this.setState({rows:response.data, isLoading: false});
        })
        .catch(error=>{
            console.log(error);
            this.setState({isLoading: false});
        });
    }

    /**
     * Handler for downloading files.
     */
    downloadHandler=(templatename)=>{
        axiosData.get(`/v1/OfficeExport/${this.props.nodeId}/${this.props.eventId}/${templatename}`, {responseType: 'blob'})
        .then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', templatename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error=>console.log(error));
    }

    render(){

        return(
            <React.Fragment>
                <h4>{this.props.Title}</h4>
                {/**Table component to display files. For docs see react-table */}
                <ReactTable
                    data={this.state.rows}
                    minRows={0}
                    className={'-striped -highlight'}
                    style={{height:'calc(250px)'}}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i'))>-1 : true
                    }}
                    columns={
                        [
                            {Header:'Template name', accessor: 'TemplateName'},
                            {width:150,filterable:false,Cell:({row})=>{
                                return(
                                <ButtonGroup>
                                        <Button variant="primary" onClick={() => this.downloadHandler(row.TemplateName)}><FontAwesomeIcon className="fa-lg" icon="download" /></Button>
                                </ButtonGroup>
                            )}}
                        ]
                    }
                />
            </React.Fragment>
        );
    }
}

export default withContext(List);