import React from 'react';
import * as yup from 'yup';
import {Button, Container} from 'react-bootstrap';
import BootstrapInput from '../../components/FormInput/BootstrapInput2';
import withContext from '../../hoc/withContext';

const schema = yup.object().shape({
    Email: yup.string().required('Required.'),
    OldPassword: yup.string().required('Required.'),
    NewPassword: yup.string().required('Required.'),
    NewPassword2: yup.string().oneOf([yup.ref('NewPassword')], 'Passwords must match')
});

class Reset extends React.Component{
    state={
        data: {
            Email: this.props.context.user ? this.props.context.user.name : '',
            OldPassword: '',
            NewPassword: '',
            NewPassword2: ''
        },
        error:{}
    }

    componentDidMount = () => {
        this.props.context.setHeading('Reset Password');
    }

    onChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        const data = {...this.state.data};
        const error = {...this.state.error}
        data[name] = value;
        error[name] = undefined;
        this.setState({data:data, error: error});
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        schema.validate(this.state.data, {abortEarly: false}).then(values=>{
            this.props.context.reset(this.state.data.Email, this.state.data.OldPassword, this.state.data.NewPassword);
        }).catch(errors=>{
            console.log(errors);
            const error = {};
            errors.inner.forEach(el=>{
                error[el.path] = el.message;
            });
            this.setState({error:error});
        });
    };

    render(){
        return(
            <Container className="content-overlay">
                <form>
                    <BootstrapInput name="Email" label="Email" onChange={this.onChange} error={this.state.error} readOnly value={this.state.data.Email}/>
                    <BootstrapInput name="OldPassword" label="Old Password" type="password" onChange={this.onChange} error={this.state.error}/>
                    <BootstrapInput name="NewPassword" label="New Password" type="password" onChange={this.onChange} error={this.state.error}/>
                    <BootstrapInput name="NewPassword2" label="New Password Again" type="password" onChange={this.onChange} error={this.state.error}/>
                    <Button type="button" onClick={this.handleSubmit} variant="success">Reset</Button>
                </form>
            </Container>
        );
    }
}



export default withContext(Reset);