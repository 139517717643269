import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import BootstrapInput from '../FormInput/BootstrapInput2';
import Select from '../Select/Select';
import Switch from '../Switch/Switch';
import {Draggable} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


/**
 * Constants for sort order applied on columns.
 */
export const SORT_ORDER = [
    { label: 'Default', value: 0 },
    { label: 'ASC', value: 1 },
    { label: 'DESC', value: 2 }
];

/**
 * This component is part of DataView create/update form. It wraps settings of individual columns.
 * Component is wrapped in Draggable to provide drag-drop functionality. See react-beatiful-dnd. 
 * Props:
 * name: parent name, it is dynamic parent form so input names must be like parent[0].children
 * value: object holding all settings of the column and providing to parent
 * onChange: callback called on every input change
 * onRemove: callback called on removing this column from parent
 */
export default class extends React.Component {
    
    shouldComponentUpdate=(newProps)=>{
        return (this.props.index !== newProps.index ||
            this.props.value.EventField !== newProps.value.EventField ||
            this.props.value.DisplayName !== newProps.value.DisplayName ||
            this.props.value.Filter !== newProps.value.Filter ||
            (this.props.value.ColumnOrder && newProps.value.ColumnOrder && 
                this.props.value.ColumnOrder.value !== newProps.value.ColumnOrder.value)  ||
            this.props.value.Width !== newProps.value.Width ||
            this.props.value.WordWrap !== newProps.value.WordWrap ||
            this.props.value.Visible !== newProps.value.Visible);
    }

    render() {
        const {name, value, onChange, onRemove} = this.props;
        return (
            <Draggable
                draggableId={value.EventField}
                index={this.props.index}
                type="COLS"
            >
                {(provided, snapshot)=>(
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Card 
                            className="DataViewColumn" 
                        >
                            <Card.Body>
                                <Row>
                                    <Col sm={2}><BootstrapInput name={name + '.EventField'} label="Name" value={value.EventField} disabled={true}/></Col>
                                    <Col sm={2}><BootstrapInput name={name + '.DisplayName'} label="Display Name" value={value.DisplayName} onChange={onChange}/></Col>
                                    <Col sm={2}><BootstrapInput name={name + '.ColumnOrder'} label="Column Order" component={Select} value={value.ColumnOrder} options={SORT_ORDER} onChange={onChange}/></Col>
                                    <Col sm={2}><BootstrapInput name={name + '.Width'} label="Width" type="number" value={value.Width} onChange={onChange}/></Col>
                                    <Col sm={2} style={{paddingTop:'1.5rem'}}><BootstrapInput name={name + '.WordWrap'} label="Word Wrap" value={value.WordWrap} disableLabel onChange={onChange} component={Switch}/></Col>
                                    <Col sm={2} style={{paddingTop:'1.5rem'}}><BootstrapInput name={name + '.Visible'} label="Visible" value={value.Visible} disableLabel options={SORT_ORDER} onChange={onChange} component={Switch}/></Col>
                                    {onRemove ? <FontAwesomeIcon onClick={onRemove} className="remove-icon" icon="times"/> : null}
                                </Row>
                                <Row>
                                    <Col><BootstrapInput name={name + '.Filter'} label="Filter" value={value.Filter} onChange={onChange} info="For filtering by user use {{[@Me]}}"/></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                )}
            </Draggable>
        );
    }

    
}