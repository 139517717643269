import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { SORT_ORDER } from '../../components/DataViewColumn/DataViewColumn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../helpers/axiosWebEntry';
import axiosData from '../../helpers/axiosData';
import withContext from '../../hoc/withContext';
import { isDate } from '../../helpers/enums';
import { get_locale } from '../../helpers/utils';
import { format, toDate } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import AsyncSelect from '../../components/Select/AsyncSelect';


const loc = get_locale();

class Detail extends React.Component {
    _isMount = false;

    state = {
        rows: [],
        isLoading: false,
        selectedView: undefined
    }

    loadRows = () => {
        this.setState({ isLoading: true }, () => {
            axiosData.post('/old/calls/custom', { url: this.state.data.URL })
                .then(response => {
                    this.setState({ rows: response.data.Events.Values, isLoading: false });
                })
                .catch(error => {
                    this.props.context.pushAlert({ variant: 'danger', message: 'Unable load data.' });
                    this.setState({ isLoading: false });
                    console.log(error);
                });
        });
    }

    loadView = (id) => {
        this.props.context.showLoading();
        axios.get(`/eventlistview/${id}`)
            .then(response => {
                const data = JSON.parse(response.data);


                this.props.context.setHeading(this.props.context.t(data.WebEntries[0].EventTypePageEntity.EventType.DisplayName));
                data.Columns.sort((a, b) => a.Order > b.Order ? 1 : -1);
                data.Columns = data.Columns.map(el => ({
                    accessor: el.EventField.Name,
                    Header: el.DisplayName ? this.props.context.t(el.DisplayName) : this.props.context.t(el.EventField.Name),
                    width: el.Width ? el.Width : undefined,
                    show: el.Visible,
                    filter: el.Filter ? (el.Filter.trim() === '{{[@Me]}}' ? this.props.context.user.name : el.Filter) : undefined,
                    Filter: el.Filter ? ({ filter, onChange }) => (<span></span>) : undefined,
                    ColumnOrder: SORT_ORDER.find(o => o.value === el.ColumnOrder),
                    className: el.WordWrap ? 'wordWrap' : undefined,
                    headerClassName: el.WordWrap ? 'wordWrap' : undefined,
                    Cell: prop => {
                        if (prop.value && isDate(el.EventField.BuiltInType)) {
                            return (<span>{format(toDate(prop.value), 'P p', { locale: loc })}</span>)
                        }
                        return (<span>{prop.value ? prop.value.toString() : null}</span>)
                    }
                }));

                //delete button for admin
                //if (this.props.context.user && this.props.context.user.roles && this.props.context.user.roles.includes('Admin')) {
                //    data.Columns.Add({
                //        accessor: "DeleteButtonColumn",
                //        Header: "Actions",
                //        //width: undefined,
                //        show: true,
                //        ColumnOrder: 999999,
                //        Cell: ({ cell }) => (
                //            <Button onClick={this.deleteEntry} variant="danger">{this.props.context.t("Delete")}</Button>
                //            <button value={cell.row.values.name} onClick={props.handleClickGroup}>
                //                {cell.row.values.name}
                //            </button>
                //        )
                //    })
                //}


                data.sort = data.Columns.filter(el => el.ColumnOrder.value !== 0).map(el => ({
                    id: el.accessor,
                    desc: el.ColumnOrder.value > 1 ? true : false
                }));
                if (this._isMount) {
                    this.setState({ data: data, isLoading: true, selectedView: { label: this.props.context.t(data.Name), value: data.ID } }, () => {
                        this.loadRows();
                    });
                }
                this.props.context.hideLoading();

            })
            .catch(error => {
                console.log(error);
                this.props.context.hideLoading();
            });
    }

    //deleteEntry = () => {
    //    if (!window.confirm('Do you really want to delete this entry? This can not be undone.')) {
    //        return;
    //    }

    //    this.props.context.showLoading();

    //    //const reqData = {};
    //    //reqData['eventid'] = this.state._eventId;
    //    //reqData['nodeid'] = this.state._nodeId;


    //    axiosData.delete(`/v1/${this.state.entry.EventType.Name}/event/${this.state._eventId}`, { headers: { 'Content-Type': 'application/json; charset=utf-8' }, data: { NodeId: this.state._nodeId } })
    //        .then(response => {
    //            this.props.context.hideLoading();
    //            if (response.data != undefined && response.data.Result) {
    //                this.props.context.pushAlert({ variant: 'success', message: 'Item deleted.' });
    //            }
    //            else {
    //                this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when deleting the item.' });
    //            }
    //        })
    //        .catch(error => {
    //            this.props.context.hideLoading();
    //            console.log(error);
    //        });
    //}

    componentWillUnount = () => {
        this._isMount = false;
    }

    componentDidMount = () => {
        this._isMount = true;
        const { match: { params } } = this.props;

        if (params.id)
            this.loadView(params.id);
    }

    onChangeView = (selected) => {
        this.setState({ selectedView: selected.target.value }, () => {
            this.loadView(this.state.selectedView.value);
        });
    }

    getViews = (query) => {
        return axios.get('/eventlistview', { params: { name: query, eventType: this.state.data.WebEntries[0].EventTypePageEntity.EventType.ID } }).then(response => {
            if (response && response.data) {
                const tmp = response.data.map((el, index) => ({ value: el.id, label: this.props.context.t(el.name) }));
                return tmp;
            }
            return [];
        }).catch(error => console.log(error));
    }

    render() {
        if (this.state.data) {
            return (
                <div className="big-window content-overlay">
                    <h3>
                        <Row>
                            <Col><AsyncSelect
                                loadOptions={this.getViews}
                                defaultOptions
                                value={this.state.selectedView}
                                onChange={this.onChangeView}
                                isDisabled={this.state.isLoading}
                                menuContainerStyle={{ 'zIndex': 999 }} />
                            </Col>
                            <Col xs={1} className="d-flex justify-content-end">
                                {this.state.data.NewEventTypePage ? (
                                    <FontAwesomeIcon className="add-icon" icon="plus" onClick={() => { this.props.history.push(`/dataentry/form/${this.state.data.NewEventTypePage.ID}`) }} />
                                ) : null}
                                <FontAwesomeIcon className="refresh-icon" icon="sync-alt" onClick={this.loadRows} />
                            </Col>

                        </Row>


                    </h3>
                    <ReactTable
                        columns={this.state.data.Columns}
                        data={this.state.rows}
                        sorted={this.state.data.sort}
                        loading={this.state.isLoading}
                        minRows={0}
                        filterable
                        className={'-striped -highlight'}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id
                            return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i')) > -1 : true
                        }}
                        defaultFiltered={this.state.data.Columns.filter(c => c.filter).map(c => ({ id: c.accessor, value: c.filter }))}
                        style={{ maxHeight: '75vh' }}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    const webEntry = this.state.data.WebEntries.find(el => el.EventTypePageEntity.EventType.ID === rowInfo.original._EventTypeID);
                                    //pushing a history.location.state with goBackSteps = -1 so the Save&Exit button will go here
                                    this.props.history.push({ pathname: `/dataentry/form/${webEntry.EventTypePageEntity.ID}/${rowInfo.original._EventID}/${rowInfo.original._NodeId}/${rowInfo.original._ParentID}`, state: { goBackSteps: -1, passedNodeId: rowInfo.original._NodeId } });

                                },
                                style: { cursor: 'pointer' }
                            };
                        }}
                    />
                </div>
            );
        }
        return null;
    }
}

export default withContext(Detail);