import * as JWT from 'jwt-decode';

/**
 * func to dynamically set authorization header on current JWT token with every call
 */
export default () => {

    let token = undefined;
    if (localStorage.getItem('ems.idtoken'))
        token = localStorage.getItem('ems.idtoken');
    //else if (localStorage.getItem('adal.idtoken')) {
    //    token = localStorage.getItem('adal.idtoken');
    //}
    else if (localStorage.getItem('okta-token-storage')) {
        let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
        if (oktaStorage !== undefined && oktaStorage.idToken !== undefined && oktaStorage.idToken.value !== undefined) {
            token = oktaStorage.idToken.value;
        }
    }

    //todo check expiry date
    //if not delete from local storage
    else
        return {};

    let jwttoken = JWT(token);
    let seconds = Math.round((new Date()).getTime() / 1000);

    if (jwttoken.exp < seconds) {
        localStorage.removeItem('ems.idtoken')
        localStorage.removeItem('adal.idtoken')
        localStorage.removeItem('okta-token-storage')
        return {};
    }

    return { 'Authorization': 'Bearer ' + token };
}