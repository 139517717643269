import React from 'react';
import Panel from '../CollapsePanel/CollapsePanel';
import BootstrapInput from '../FormInput/BootstrapInput2';
import Switch from '../Switch/Switch';
import Datepicker from '../Datepicker/Datepicker';
import Select from '../Select/Select';
import RadioGroup from '../RadioGroup/RadioGroup';
import { isBool, isDate, isString, isNumeric } from '../../helpers/enums';

/**
 * Return input based on data type DatePicker for date, Select for Enums etc.
 */
const getInputProps = (field, enumparams) => {
    const inputProps = {};
    if (isNumeric(field.EventField.BuiltInType))
        inputProps.type = 'number';

    if (isString(field.EventField.BuiltInType) && field.UseTextarea && field.EventField.ValidationFunctionName !== 'enum')
        inputProps.as = 'textarea';

    if (isString(field.EventField.BuiltInType) && field.EventField.ValidationFunctionName === 'enum') {
        inputProps.component = field.UseBtnWidget ? RadioGroup : Select;
        inputProps.options = enumparams; //field.EventField.ValidationFunctionParameter.split(';').filter(el => el.trim() !== '').map(el => ({ label: el.trim(), value: el.trim() }));
    }

    if (isBool(field.EventField.BuiltInType)) {
        inputProps.component = Switch;
        inputProps.disableLabel = true;
    }

    if (isDate(field.EventField.BuiltInType)) {
        inputProps.component = Datepicker;
    }

    return inputProps;


}

/**
 * Component of filed on DataEntry form - form for customers.
 * Props:
 * - value: object holding values of all inner fields
 * - visible: visibility of group
 * - onChange: callback called on every change of inner inputs
 * - error: object of all form errors
 * - closed: if groups is collapsed by default
 * - group: settings of group itself
 */
class Group extends React.Component {

    render() {
        const { group, value, onChange, error, visible, allVisibility } = this.props;
        const check = error && group.EventFieldsGroupEventFields.filter(el => el.Visible).some(el => error[el.EventField.Name]);
        const style = visible === 0 ? { display: 'none' } : { display: 'block' };
        //debugger;
        return (
            <Panel hasError={check} closed={this.props.closed} style={style}>
                <Panel.Header>{this.props.context.t(this.props.group.Name)}</Panel.Header>
                <Panel.Body>
                    {/** inserting unescaped html directly - normaly considered as dangerous */}
                    {group.InfoText ? <div dangerouslySetInnerHTML={{ __html: this.props.context.t(group.InfoText) }}></div> : null}
                    {group.EventFieldsGroupEventFields.filter(el => el.Visible).map((f, i) => (
                        <BootstrapInput
                            key={i}
                            name={f.EventField.Name}
                            label={
                                /**
                                 * display name of field and add red asterisk if required
                                 */
                                <span>{this.props.context.t(f.DisplayName ? f.DisplayName : f.EventField.Name)} {f.Required ? <span className="required-sign">*</span> : null}</span>
                            }
                            value={value[f.EventField.Name]}
                            readOnly={f.ReadOnly}
                            disabled={f.ReadOnly}
                            isDisabled={f.ReadOnly}
                            onChange={onChange}
                            error={error}
                            visible={allVisibility[f.EventField.Name]}
                            {...getInputProps(f, this.props.enumValues[f.EventField.Name])}
                        />
                    ))}
                </Panel.Body>
            </Panel>
        );
    }
}

export default Group;