import React from 'react';
import Field from './DataEntryField';

/**
 * Component wrapping DataEntryFields for better rerendering handling.
 */
class Fields extends React.Component{
    /*shouldComponentUpdate=(nextProps)=>{
        return this.props.fields !== nextProps.fields ||
            this.props.index !== nextProps.index;
    }*/

    render(){
        return(
            this.props.fields.map((f,k)=>(
                <Field name={`Groups[${this.props.index}].EventFieldsGroupEventFields[${k}]`} key={k} index={k} value={f} onChange={this.props.onChange}/>
            ))
        );
    }
}

export default Fields;