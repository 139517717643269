import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Form from './Form';
import Roster from './Roster';
import List from './List';
import Detail from './Detail';
import {Container} from 'react-bootstrap';

export default props => (
    <Container>
        <Switch>
            <Route path="/dataview/create" component={Form}/>
            <Route path="/dataview/update/:id" component={Form}/>
            <Route path="/dataview/detail/:id" component={Detail}/>
            <Route path="/dataview/roster" component={Roster}/>
            <Route path="/dataview/" component={List}/>
        </Switch>
    </Container>
);

