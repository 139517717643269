import React from 'react';
import { AuthConsumer } from '../contexts/Context';

/**
 * High Order Component of Global Context. Simple way how to connects to global state.
 */
export default Component => {
    return function WrapperComponent(props) {
        return (
            <AuthConsumer>
                {state => <Component {...props} context={state} />}
            </AuthConsumer>
        );
    };
}