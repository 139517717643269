import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from '../../helpers/axiosWebEntry';
import withContext from '../../hoc/withContext';
import ReactTable from 'react-table';
import {ButtonGroup, Button} from 'react-bootstrap';



class List extends React.Component {

    state={
        rows:[]
    }

    addHandler = () => {
        this.props.history.push('/dataview/create/');
    }

    removeHandler = (id) => {
        this.props.context.showLoading();
        axios.delete(`/eventlistview/${id}`)
        .then(response => {
            this.props.context.pushAlert({variant:'success', message: 'Item deleted.'});
            this.loadRows();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.pushAlert({variant:'danger', message: 'Unable to delete item.'});
            this.loadRows();
        });
    }

    loadRows = () => {
        this.props.context.showLoading();
        axios.get('/eventlistview?loadDisabled=true')
        .then(response => {
            this.setState({rows:response.data});
            this.props.context.hideLoading();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.hideLoading();
        });
    }

    componentDidMount=()=>{
        this.props.context.setHeading('Data View Settings');
        this.loadRows();
    }

    render() {
        return (
            <div className="content-overlay">
                <h2>Data Views 
                    <FontAwesomeIcon className="add-icon" icon="plus" onClick={()=>{this.props.history.push('/dataview/create/')}}/>
                </h2>
                <ReactTable
                    data={this.state.rows}
                    minRows={0}
                    style={{height:'calc(100% - 200px)'}}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i'))>-1 : true
                    }}
                    columns={
                        [
                            {accessor: 'id', show: false},
                            {Header:'Name', accessor: 'name'},
                            {Header: 'Event Type', accessor: 'url'},
                            {id: 'actions', filterable:false,width:150,Cell:({row})=>{
                                if(row._original){
                                    return(
                                        <ButtonGroup>
                                            <Button variant="primary" onClick={() => {this.props.history.push(`/dataview/update/${row._original.id}`)}}><FontAwesomeIcon className="fa-lg" icon="pen" /></Button>
                                            <Button variant="danger" onClick={() => {this.removeHandler(row._original.id)}}><FontAwesomeIcon className="fa-lg" icon="times" /></Button>
                                        </ButtonGroup>
                                    )
                                }
                            }}
                        ]
                    }
                    getTdProps={(state, rowInfo, column, instance) => {
                        if(column.id!=='actions')
                            return {
                                onClick: (e, handleOriginal)=>{
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                    this.props.history.push(`/dataview/detail/${rowInfo.row.id}`);

                                },
                                style: {cursor: 'pointer'}
                            };
                        return {};
                    }}
                />
            </div>
        );
    }
}

export default withContext(List);