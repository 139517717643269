import React from 'react';
import AsyncSelect from 'react-select/lib/Async';

/**
 * Async variant of Select component which loads optons via ajax.
 * Props:
 * onChange: onchange callback provided by parent form
 * name: input name
 * options: initial options
 * value: current value of select
 */
class _Async extends React.PureComponent {
    /**
     * On Change handler. It modifies inner structure of change event to by compatible with other components.
     */
    handleChange = value => {
        const e = {target:{name: this.props.name, value: value}};
        this.props.onChange && this.props.onChange(e);
        
    }

    /**
     * Modifies inter onBlur payload.
     */
    handleBlur = () => {
        this.props.onBlur && this.props.onBlur(this.props.name, true);
    }
    render(){
        const {onChange, options, value, ...rest} = this.props;
        return(
            <AsyncSelect
                cacheOptions
                loadOptions={this.props.loadOptions}
                onChange={this.handleChange}
                value={value}
                {...rest}
            />
        );
    }
}

export default _Async;