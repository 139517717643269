import locale from 'date-fns/locale/index';

/**
 * Helper method for inplace switching possitions in array.
 */
export const array_move = (arr, old_index, new_index) =>  {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

/**
 * Get current browser language.
 */
export const get_lang = () => {
    const lang = navigator.language || navigator.browserLanguage || ( navigator.languages || [ "en" ] )[ 0 ];
    return lang;
}

/**
 * Gets current browser locale.
 */
export const get_locale = () => {
    const loc = get_lang();
    if (locale.hasOwnProperty(loc))
        return locale[loc];

    if (locale.hasOwnProperty(loc.replace('-', '')))
        return locale[loc.replace('-', '')];

    if (loc.indexOf('-') > -1 && locale.hasOwnProperty(loc.substring(0, loc.indexOf('-'))))
        return locale[loc.substring(0, loc.indexOf('-'))];

    return locale['enGB'];
}

/**
 * Checks if URL is absolute. Used by tiles.
 */
export const isUrlAbsolute = (url) => {
    let pat = /^https?:\/\//i;
        return pat.test(url);
}