import 'babel-polyfill';

import { AdalConfig, AdalAuthObject } from './helpers/adalConfig'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/Context';
import * as serviceWorker from './serviceWorker';
//import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
//import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
//import { AuthOktaProvider } from './helpers/oktaAuth';

if (AdalAuthObject !== undefined)
    AdalAuthObject.handleWindowCallback();



//const oktaAuth = new OktaAuth(OktaConfig);

//const restoreOriginalUri = async (_oktaAuth, originalUri) => {
//    this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
//};


if ((window === window.parent) && window === window.top) {
    // Having both of these checks is to prevent having a token in localstorage, but no user.
    if (AdalAuthObject !== undefined && !AdalAuthObject.isCallback(window.location.hash) && AdalAuthObject.getCachedUser()) {
        AdalAuthObject.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
            if (token) {
                ReactDOM.render(<Router><AuthProvider><App /></AuthProvider></Router>, document.getElementById('root'));
            }
        })
    }
    //else if (AuthOktaContext) {
    //    var token = AuthOktaContext.getAccessToken();
    //    if (token) {
    //        ReactDOM.render(<Router><AuthProvider><App /></AuthProvider></Router>, document.getElementById('root'));
    //    }

    //}
    else {
        ReactDOM.render(<Router><AuthProvider><App /></AuthProvider></Router>, document.getElementById('root'));
    }
    serviceWorker.unregister();
}
