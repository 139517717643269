import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import withContext from '../../hoc/withContext';

/**
 * Top navbar component. It uses rect-bootstrap. Changes class when scrolled down.
 */
class NavMenu extends React.Component {
    
    state = {
        /**
         * is page scolled to top
         */
        is_Scroll_0: true
    }

    /**
     * On scroll callback.
     */
    onScroll = e =>{
        if (window.scrollY > 100 && this.state.is_Scroll_0)
            this.setState({
                is_Scroll_0: false
            });
        else if (window.scrollY <= 100 && !this.state.is_Scroll_0)
            this.setState({
                is_Scroll_0: true
            });
    }

    /**
     * Attach scroll listener to drowser window.
     */
    componentDidMount=()=>{
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount=()=>{
        window.removeEventListener('scroll', this.onScroll);
    }
    
    render(){
        return (
            <Navbar fixed="top" collapseOnSelect className={this.state.is_Scroll_0 ? 'landing-navbar' : 'landing-navbar-scrolled'} variant="dark" expand="lg">
                <Navbar.Brand><Link to="/"><div className="logo"/></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between align-items-center">
                    <Nav>
                        <Nav.Item>
                            <h1 className="landing-heading">{this.props.context.heading}</h1>   
                        </Nav.Item>
                    </Nav>
                    <Nav className="user-section">
                        {this.props.context.user ? (
                            <NavDropdown alignRight title={this.props.context.user.name}>
                                <NavDropdown.Item onClick={this.props.context.logout}>
                                    Logout
                                </NavDropdown.Item>
                                <LinkContainer to="/changepassword/" active={false}>
                                    <NavDropdown.Item>
                                        Change Password
                                    </NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                        ) : (
                            <LinkContainer to="/login" active={false}>
                                <Nav.Link>Login</Nav.Link> 
                            </LinkContainer>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withContext(NavMenu);
