import React from 'react';
import ReactTable from 'react-table';
import axiosData from '../../helpers/axiosData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button, ButtonGroup} from 'react-bootstrap';
import withContext from '../../hoc/withContext';
import Dropzone from 'react-dropzone';

/**
 * Table to display file attachments for event.
 * Props:
 * -eventtypename
 * -eventId
 */
class List extends React.Component{

    state={
        //row/files for table
        rows:[],
        //table load spinner visibility
        isLoading: true
    }

    componentDidMount() {
        this.loadRows();
    }

    /**
     * Load files/row for table form API.
     */
    loadRows = () => {
        this.setState({isLoading: true});
        axiosData.get(`/v1/${this.props.eventtypename}/file/${this.props.eventId}`)
        .then(response => {
            this.setState({rows:response.data, isLoading: false});
        })
        .catch(error=>{
            console.log(error);
            this.setState({isLoading: false});
        });
    }

    /**
     * Drop callback of fileupload component aka Dropzone.
     */
    onDrop=(files)=>{
        let formData = new FormData();
        formData.append('file', files[0]);
        formData.append('eventId', this.props.eventId);
        axiosData.post(`/v1/${this.props.eventtypename}/file/${this.props.eventId}`, formData)
        .then(response=>{
            this.loadRows();
        })
        .catch(error=>console.log(error));
    }

    /**
     * Handler for deleting files.
     */
    removeHandler = (filename) => {
        axiosData.delete(`/v1/${this.props.eventtypename}/file/${this.props.eventId}/${filename}`)
        .then(response => {
            this.props.context.pushAlert({variant:'success', message: 'Item deleted.'});
            this.loadRows();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.pushAlert({variant:'danger', message: 'Unable to delete item.'});
            this.loadRows();
        });
    }

    /**
     * Hadnler for downloading files.
     */
    downloadHandler=(filename)=>{
        axiosData.get(`/v1/${this.props.eventtypename}/file/${this.props.eventId}/${filename}`, {responseType: 'blob'})
        .then(response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(error=>console.log(error));
    }

    render(){

        return(
            <React.Fragment>
                <h4>{this.props.Title}</h4>
                <div className="dropZone">
                    <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {isDragActive ? "Drop item here!" : 'Click me or drag a file to upload!'}
                            </div>
                        )}
                    </Dropzone>
                </div>
                {/**Table component to display files. For docs see react-table */}
                <ReactTable
                    data={this.state.rows}
                    minRows={0}
                    className={'-striped -highlight'}
                    style={{height:'calc(250px)'}}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i'))>-1 : true
                    }}
                    columns={
                        [
                            {Header:'Filename', accessor: 'FileName'},
                            {width:150,filterable:false,Cell:({row})=>{
                                return(
                                <ButtonGroup>
                                    <Button variant="primary" onClick={()=>this.downloadHandler(row.FileName)}><FontAwesomeIcon className="fa-lg" icon="download" /></Button>
                                    <Button variant="danger" onClick={()=>this.removeHandler(row.FileName)}><FontAwesomeIcon className="fa-lg" icon="times" /></Button>
                                </ButtonGroup>
                            )}}
                        ]
                    }
                />
            </React.Fragment>
        );
    }
}

export default withContext(List);