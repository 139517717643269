import React from 'react';
import {Form} from 'react-bootstrap';


/**
 * Custom radiogroup component.
 * Props:
 * options: name-value pairs of options
 * name: name of input
 * value: initial value and connection to parent state
 * onChange: onChange callback
 * disabled: disable whole component
 */
class RadioGroup extends React.Component {

    render(){
        const {options, name, value, onChange, disabled} = this.props;
        return (
            <React.Fragment>
                {options && options.map((el, i)=>(
                    <div
                        onClick={()=>onChange({target:{name:name, value:el}})}
                        key={i}
                    >
                        <Form.Check
                            name={name}
                            checked={value && value.value === el.value}
                            disabled={disabled}
                            value={el}
                            type="radio"
                            label={el.label}
                            onChange={()=>onChange({target:{name:name, value:el}})}
                    />
                    </div>
                    
                ))}
            </React.Fragment>
        );
    }
}

export default RadioGroup;