import React from 'react';
import Select from 'react-select';

/**
 * Select component without ajax possibility.
 * Props:
 * name: input name
 * onChange: onChange callback provided by parent
 * value: current value
 * options: options for Select
 */
class _Select extends React.Component {
    
    /**
     * Modifies internal event payload to be compatible with other components
     */
    handleChange = value => {
        this.props.onChange && this.props.onChange({target:{name: this.props.name, value: value}});
    }

    handleBlur = () => {
        this.props.onBlur && this.props.onBlur(this.props.name, true);
    }

    render() {
        const {onChange, onBlur, options, value, ...rest} = this.props;
        return(
            <Select
                options={options}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={value}
                {...rest}
            />
        );
    }
}

export default _Select;