import React from 'react';
import ReactQuill from 'react-quill';

/**
 * WYSIWYG Editor component. Usres ReactQuill.
 */
class Editor extends React.Component{

    /**
     * This callback is wrapping props.onChange callback to modify values before call.
     */
    onChange=(val)=>{
        this.props.onChange({target:{value:val, name:this.props.name}});
    }

    render(){
        return(
            <ReactQuill className={this.props.className} onChange={this.onChange} name={this.props.name} value={this.props.value}
                formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', //'image'
                ]}
                modules={{
                    toolbar: [
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, 
                         {'indent': '-1'}, {'indent': '+1'}],
                        ['link', /*'image'*/],
                        ['clean']
                    ]
                }}
            />
        );
    }
}

export default Editor;