import React from 'react';
import Switch from 'react-switch';

/**
 * Switch component insted of normal checkbox.
 * Props:
 * - label: input label
 * - name: input name
 * - onChange: onChange callback
 * - value: current value
 * - changedInject: additional callback called after onChange
 */
export default class extends React.Component {

    /**
     * Change default rerender logic to avoid unecessary rerendering.
     */
    shouldComponentUpdate = (nextProps) => {
        return this.props.value !== nextProps.value ||
            this.props.name !== nextProps.name
    }

    /**
     * OnChange callback wrapper with additional logic.
     */
    handleChange = (checked) => {
        const { onChange, changedInject, name } = this.props;
        if (onChange)
            onChange({ target: { name: name, value: checked } });
        if (changedInject)
            changedInject();
    }

    render() {
        const { onChange, changedInject, name, label, ...rest } = this.props;
        return (
            <div style={{ display: 'flex', alignItems: 'top' }}>
                <label style={{ display: 'flex', alignItems: 'top' }}>
                    <Switch {...rest} name={name}
                        onChange={this.handleChange}
                        checked={this.props.value}
                        className="switch-body"
                    />
                    <span className="switch-label">{label}</span>
                </label>
            </div>
        );
    }
}