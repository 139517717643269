import React from 'react';
import {Card,Collapse} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col } from 'react-bootstrap';

/**
 * Component for collapsible panel.
 */
export default class extends React.Component {
    //open/close state
    state={open: !this.props.closed}

    /**
     * Open/close panel
     */
    toggle = (e) => {
        const {open} = this.state;
        this.setState({open: !open});
    }

    componentWillUpdate=()=>{

    }

    /**
     * Header subcomponent.
     * Props:
     * - toggle: toggle callback
     * - opened: opened by default
     * - children: inner content provided by react itself
     * - onRemove: remove callback
     */
    static Header = ({toggle,opened,children,onRemove}) => {
        return (
            <Card.Header>
                <Row className="align-items-center" onClick={toggle}>
                    <Col sm={1}>{opened ? <FontAwesomeIcon icon="minus"/> : <FontAwesomeIcon icon="plus"/>}</Col>
                    <Col sm={10}>{children}</Col>   
                    {onRemove ? <FontAwesomeIcon onClick={onRemove} className="remove-icon" icon="times"/> : null}
                </Row>
                
            </Card.Header>
        );
    }

    /**
     * Panel Body subcomponent.
     * Props:
     * opened: opened by default
     * children: inner content provided by react
     */
    static Body = ({opened, children}) => (
        <Collapse in={opened}>
            <div>
                <Card.Body>{children}</Card.Body>
            </div>
        </Collapse>
    );

    componentDidUpdate=(prevProps)=>{
        if(!this.state.open && prevProps.hasError){
            this.setState({open: true});
        }
    }

    render() {
        const {children, style} = this.props;
        const tmp = React.Children.map(children, child => React.cloneElement(child, {
            toggle: this.toggle,
            opened: this.state.open || this.props.hasError
        }));
        return(
            <Card className="Panel" style={style}>
                {tmp}
            </Card>
        );
    }
}