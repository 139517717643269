import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Form from './Form';
import List from './List';
import Entry from './Entry';
import {Container} from 'react-bootstrap';

export default props => (
    <Container className="content-overlay">
        <Switch>
            <Route path="/dataentry/create" component={Form}/>
            <Route path="/dataentry/update/:id" component={Form}/>
            <Route path="/dataentry/form/:id/:eventId/:nodeId(\d+)?/:parentId(\d+_\w+-\w+-\w+-\w+-\w+)?/:parentFormId(\d+)?/:parentNodeId(\d+)?" component={Entry} />
            <Route path="/dataentry/form/:id" component={Entry}/>
            <Route path="/dataentry/child/:id/:parentId" component={Entry}/>
            <Route path="/dataentry/" component={List}/>
        </Switch>
    </Container>
);

