import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    "bn": { translation: require('./bn.json') },
    "cs": { translation: require('./cs.json') },
    "de": { translation: require('./de.json') },
    "en": { translation: require('./en.json') },
    "es": { translation: require('./es.json') },
    "fi": { translation: require('./fi.json') },
    "fr": { translation: require('./fr.json') },
    "it": { translation: require('./it.json') },
    "ko": { translation: require('./ko.json') },
    "pt": { translation: require('./pt.json') },
    "ru": { translation: require('./ru.json') },
    "sv": { translation: require('./sv.json') },
    "zh": { translation: require('./zh.json') }

};

//const userLang = navigator.language || navigator.userLanguage;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en",
        nonExplicitSupportedLngs: true,
        nsSeparator: false,
        keySeparator: false,
        
        //lng: "cz",

        //keySeparator: false, // we do not use keys in form messages.welcome

        //interpolation: {
            //escapeValue: false // react already safes from xss
        //}
    });

export default i18n;