import React from 'react';

/**
 * Display form field error mesage when invalid.
 */
export default props => (
    <div style={{
        backgroundColor: 'red', 
        color: 'white',
        width: '100%',
        padding:'3px',
        borderRadius: '0.25rem'
    }}>{props.children}</div>
);