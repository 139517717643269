import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from '../contexts/Context';
import { AdalAuthObject, AdalConfig } from './adalConfig';
import withContext from '../hoc/withContext';

/**
 * Enhanced Route from react-router-dom. It handles redirecting on Login page if user is not logged in.
 */



const ProtectedRoute = ({ component: Component, ...rest }) => (
    <AuthConsumer>
        {({user}) => (
            <Route
                render={
                    props => {
                        var u0 = rest.context.user;
                        if (u0 === undefined)
                            rest.context.triggerComponentDidMountToSaveUser();

                        if (
                            user ||
                            (AdalAuthObject !== undefined && AdalConfig !== undefined && AdalAuthObject.getCachedToken(AdalConfig.clientId)) || 
                            (AdalAuthObject !== undefined && AdalAuthObject.getCachedUser()) ||
                            localStorage.getItem('okta-token-storage')
                        ) {
                            return <Component {...props} />
                        } else {
                            return (<Redirect to={`/login/${encodeURIComponent(window.location.pathname)}`} />);
                        }
                    }
                } 
                {...rest}
            />
        )}
    </AuthConsumer>
);
export default withContext(ProtectedRoute);