export const BUILTIN_TYPE = [
    { label:'Void', value: 0 },
    { label:'Char', value: 1 },
    { label:'Int16', value: 2 },
    { label:'Int32', value: 3 },
    { label:'Int64', value: 4 },
    { label:'Byte', value: 5},
    { label:'UInt16', value: 6 },
    { label:'UInt32', value: 7 },
    { label:'UInt64', value: 8 },
    { label:'Single', value: 9 },
    { label:'Double', value: 10 },
    { label:'Boolean', value: 11 },
    { label:'String', value: 12 },
    { label:'TypeInfo', value: 13 },
    { label:'Status', value: 14 },
    { label:'DateTime', value: 15 },
    { label:'Guid', value: 16 },
    { label:'SByte', value: 17 },
    { label:'Decimal', value: 18 },
    { label:'TimeSpan', value: 19 }
];

export const isNumeric=(val)=>{
    return (val>1 && val<11) || (val>16 && val<19);
}

export const isString=(val)=>{
    return val===12 || val===1 || val===16;
}

export const isDate=(val)=>{
    return val===15;
}

export const isBool=(val)=>{
    return val===11;
}