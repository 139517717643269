import React from 'react';
import ReactTable from 'react-table';
import {ButtonGroup, Button} from 'react-bootstrap';
import axios from '../../helpers/axiosWebEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withContext from '../../hoc/withContext';

class List extends React.Component{

    state={
        rows:[]
    }

    componentDidMount() {
        this.props.context.setHeading('Data Entry Settings');
        this.loadRows();
    }

    addEntryHandler = (event) => {
        this.props.history.push('/dataentry/create/');
    }

    loadRows = () => {
        this.props.context.showLoading();
        axios.get('/eventtypepage?loadDisabled=true')
        .then(response => {
            this.setState({rows:response.data});
            this.props.context.hideLoading();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.hideLoading();
        });
    }

    removeHandler = (id) => {
        this.props.context.showLoading();
        axios.delete(`/eventtypepage/${id}`)
        .then(response => {
            this.props.context.pushAlert({variant:'success', message: 'Item deleted.'});
            this.loadRows();
        })
        .catch(error=>{
            console.log(error);
            this.props.context.pushAlert({variant:'danger', message: 'Unable to delete item.'});
            this.loadRows();
        });
    }

    toggleHandler = (id) => {
        axios.get(`/eventtypepage/${id}/toggle`)
        .then(response=> {
            this.props.context.pushAlert({variant:'success', message: 'Item set hidden.'});
            this.loadRows();
        })
        .catch(error => {
            this.props.context.pushAlert({variant:'danger', message: 'Unable to hide item.'});
            console.log(error);
        });
    }


    render(){

        return(
            <React.Fragment>
                <h2>Data Entries 
                    <FontAwesomeIcon className="add-icon" icon="plus" onClick={this.addEntryHandler}/>
                </h2>
                <ReactTable
                    data={this.state.rows}
                    minRows={0}
                    className="-striped -highlight"
                    style={{height:'calc(100% - 200px)'}}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).search(new RegExp(filter.value, 'i'))>-1 : true
                    }}
                    columns={
                        [
                            {Header:'Name', accessor: 'name'},
                            {Header: 'Event Type', accessor: 'eventType'},
                            {width:150,filterable:false,Cell:({row})=>{
                                if(row._original){
                                    return(
                                        <ButtonGroup>
                                            <Button variant="primary" onClick={() => {this.props.history.push(`/dataentry/update/${row._original.id}`)}}><FontAwesomeIcon className="fa-lg" icon="pen" /></Button>
                                            <Button variant={!row._original.disabled ? 'success' : 'danger'} onClick={() => this.toggleHandler(row._original.id)}><FontAwesomeIcon className="fa-lg" icon={!row._original.disabled ? 'eye' : 'eye-slash'} /></Button>
                                            <Button variant="danger" onClick={() => this.removeHandler(row._original.id)}><FontAwesomeIcon className="fa-lg" icon="times" /></Button>
                                        </ButtonGroup>
                                    )
                                }
                            }}
                        ]
                    }
                />
            </React.Fragment>
        );
    }
}

export default withContext(List);