import React from 'react';
import BootstrapInput from '../FormInput/BootstrapInput2';
import Switch from '../Switch/Switch';
import Panel from '../CollapsePanel/CollapsePanel';
import Fields from '../DataEntryField/Fields';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import Editor from '../Editor/Editor';
import {get} from 'lodash';

/**
 * Group component wrapping DataEntry fileds on create/update form. It provides drag-drop of fieds by rect-beatiful-dnd.
 * Props:
 * - name: group name
 * - index: unique identifier provided by parent for drag-drop logic
 * - value: object holding all inner input values
 * - error: objects holding errors of whole form
 * - onChange: callback called on every inner input change
 * - onRemove: callback provided by parent called on removing of whole group
 */
class Group extends React.Component{
    
    /**
     * Change default rerender logic to avoid unecessary rerendering.
     */
    shouldComponentUpdate=(nextProps)=>{
        const currError = get(this.props.error, this.props.name+ 'Name');
        const nextError = get(nextProps.error, nextProps.name + 'Name');
        return this.props.value.Name !== nextProps.value.Name ||
            this.props.value.InfoText !== nextProps.value.InfoText ||
            this.props.value.Collapsed !== nextProps.value.Collapsed ||
            this.props.index !== nextProps.index ||
            this.props.name !== nextProps.name ||
            this.props.value.EventFieldsGroupEventFields !== nextProps.value.EventFieldsGroupEventFields ||
            (currError !== undefined && nextError === undefined) ||
            (currError === undefined && nextError !== undefined) ||
            (currError !== nextError);
    }

    render(){
        const {value, error} = this.props;
        return(
            <Draggable
                draggableId={`group${this.props.index}`}
                index={this.props.index}
                type="GROUPS"
            >
                {(provided, snapshot)=>(
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Panel>
                            <Panel.Header onRemove={()=>this.props.onRemove(this.props.index)}>
                                <BootstrapInput value={value.Name} error={error} name={`${this.props.name}.Name`} onChange={this.props.onChange} label="Group Name"/>
                            </Panel.Header>
                            <Panel.Body>
                                <BootstrapInput value={value.InfoText} name={`${this.props.name}.InfoText`} onChange={this.props.onChange} label="Info Text" className="editor" component={Editor}/>
                                <BootstrapInput value={value.Collapsed} name={`${this.props.name}.Collapsed`} disableLabel label="Initially Collapsed" component={Switch} onChange={this.props.onChange}/>
                                <Droppable droppableId={`group${this.props.index}`} type="FIELDS">
                                    {(provided, snapshot)=>(
                                        <div ref={provided.innerRef} style={{minHeight:'200px'}}>
                                            <Fields index={this.props.index} fields={value.EventFieldsGroupEventFields} onChange={this.props.onChange}/>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </Panel.Body>
                        </Panel>
                    </div>
                )}
            </Draggable>
            
        );
    }
}

export default Group;