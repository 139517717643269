import React from 'react';
import axios from '../../helpers/axiosWebEntry';
//import _ from 'lodash';
import * as yup from 'yup';
import axiosData from '../../helpers/axiosData';
import axiosUser from '../../helpers/axiosUser';
import withContext from '../../hoc/withContext';
import { Button, ButtonGroup } from 'react-bootstrap';
import Group from '../../components/DataEntryGroup/DataEntryGroup'
import View from '../../components/AssociatedEventSettings/AssociatedEventsGrid';
import { isDate, isNumeric, isString } from '../../helpers/enums';
import queryString from 'query-string';
import Attachments from '../../components/Attachments/Attachments';
import OfficeExports from '../../components/OfficeExports/OfficeExports';
import debounce from 'lodash.debounce';
import Modal from 'react-bootstrap/Modal';
import AsyncSelect from '../../components/Select/AsyncSelect';
import BootstrapInput from '../../components/FormInput/BootstrapInput2';




class Entry extends React.PureComponent {
    constructor() {
        super()

        this.state = {
            entry: undefined,
            data: {},
            fields: [],
            _nodeId: undefined,
            _itemId: undefined,
            _lockState: 'Open',
            _createdBy: '',
            _ownedBy: '',
            _newOwner: '',
            _eventId: '',
            dirty: false,
            error: {},
            groupsVisibility: {},
            //visibilityControls: {},
            goBackSteps: -1,
            defaultEnumValues: {},
            enumValues: [],  //2020-04-20 vlad: added to handle filtering of enum values
            show: false,
            _parentId: undefined
        }

        // Delay action 500 miliseconds
        this.onChangeDebounced = debounce(this.onChangeDebounced, 500)
    }

    schema = yup.lazy(obj => {
        const result = {};
        for (let key in this.state.fields) {
            let field = this.state.fields[key];

            let validation = yup;
            let validationEnabled = false;

            if (isString(field.EventField.BuiltInType) && field.FormatValidation !== undefined && field.FormatValidation !== '') {
                let message = (field.FormatValidationErrorMessage !== undefined && field.FormatValidationErrorMessage !== '')
                    ? this.props.context.t(field.FormatValidationErrorMessage)
                    : (this.props.context.t('String not in the correct format, format should be "') + field.FormatValidation + '".');

                validation = validation.string().matches(RegExp(field.FormatValidation), message);
                validationEnabled = true;
            }

            else if (isNumeric(field.EventField.BuiltInType) && field.RangeValidationLow !== undefined && field.RangeValidationLow !== '' && field.RangeValidationHigh !== undefined && field.RangeValidationHigh !== '') {
                let message = (field.RangeValidationErrorMessage !== undefined && field.RangeValidationErrorMessage !== '')
                    ? this.props.context.t(field.RangeValidationErrorMessage)
                    : (this.props.context.t('Range not within low: "') + field.RangeValidationLow + this.props.context.t('" and high: "') + field.RangeValidationHigh + this.props.context.t('" limits.'));

                validation = validation.number().typeError(this.props.context.t('Must be a number')).min(field.RangeValidationLow, message).max(field.RangeValidationHigh, message);
                validationEnabled = true;
            }

            if (this.state.fields[key].Required) {
                if (validationEnabled) {
                    validation = validation.required(this.props.context.t("This Field is Required."));
                }
                else {
                    validation = validation.mixed().test({
                        test: value => (value || value === 0) ? true : false,
                        message: this.props.context.t('This Field is Required.'),
                        name: key
                    });
                }
                validationEnabled = true;
            }

            if (validationEnabled)
                result[key] = validation;
        }
        return yup.object(result);

    });

    modalClose = () => {
        this.setState({ show: false });
    }
    modalShow = () => {
        this.setState({ show: true });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let goBackSteps
        if (this.props.history.location.state) {
            goBackSteps = this.props.history.location.state.goBackSteps
        } else {
            goBackSteps = -1
        }
        const name = e.target.name;

        if (name === 'SaveLock') {
            if (!window.confirm('Are you sure you want to lock this entry?')) {
                return;
            }
        }

        if (name === 'ChangeOwner') {
            if (!window.confirm('Are you sure you want to change owner of this entry?')) {
                return;
            }
        }



        this.schema.validate(this.state.data, { abortEarly: false })
            .then(valid => {
                const { match: { params } } = this.props;
                const values = {};
                const data = { ...this.state.data };
                const fields = { ...this.state.fields };
                for (let key in fields) {
                    if (data[key] !== undefined && data[key] !== null && data[key].value !== undefined)
                        values[key] = data[key].value;
                    else
                        values[key] = data[key];

                }

                if (this.state._itemId)
                    values["id"] = this.state._itemId;

                const reqData = { Values: values };
                if (params.parentId)
                    reqData.ParentId = params.parentId;

                //if (this.state._nodeId)
                //    reqData.nodeId = params.parentId;



                /*
                schema.validate(this.state.data, {abortEarly:false})
                .then(valid=>{*/

                this.props.context.showLoading();
                if (params && params.eventId) {
                    let temp_nodeid = (this.state._nodeId) ? ("?nodeid=" + this.state._nodeId) : "";
                    axiosData.put(`/v1/${this.state.entry.EventType.Name}/event/${params.eventId}${temp_nodeid}`, reqData)
                        .then(response => {
                            if (response.data.Result) {
                                this.setState({ dirty: false });
                                this.props.context.pushAlert({ variant: 'success', message: 'Item updated.' });
                                this.props.context.hideLoading();
                                if (name === 'SaveClose') {
                                    //this.props.history.go(this.state.goBackSteps);
                                    if (this.props.history.location.state) {
                                        this.props.history.go(this.props.history.location.state.goBackSteps)
                                    } else {
                                        // You can add a default action if a user lands on route from an external link
                                        this.props.history.push('/')
                                    }
                                }
                                else if (name === 'SaveLock') {
                                    this.props.context.showLoading();
                                    const lockvals = {};
                                    lockvals['eventid'] = params.eventId;
                                    lockvals['nodeid'] = this.state._nodeId;

                                    axiosData.post('/old/calls/custompost', {
                                        url: `eventlock`,
                                        values: lockvals
                                    })
                                        .then(response => {
                                            this.props.context.hideLoading();
                                            if (response.data.Result) {
                                                this.setState({ _lockState: 'Locked' });
                                            }
                                            else {
                                                this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when locking the item.' });
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });

                                }
                                else if (name === 'ChangeOwner') {
                                    this.props.context.showLoading();
                                    const reqvals = {};
                                    reqvals['eventid'] = params.eventId;
                                    reqvals['nodeid'] = this.state._nodeId;
                                    reqvals['newowner'] = this.state._newOwner;

                                    axiosData.post('/old/calls/custompost', {
                                        url: `changeowner`,
                                        values: reqvals
                                    })
                                        .then(response => {
                                            this.props.context.hideLoading();
                                            if (response.data.Result) {
                                                this.setState({ _ownedBy: this.state._newOwner });
                                                this.modalClose();
                                                this.props.context.pushAlert({ variant: 'success', message: 'Owner changed.' });
                                                //reload
                                                this.loadEvent(this.state.entry.EventType.Name, this.state._eventId, this.state._nodeId);
                                            }
                                            else {
                                                this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when changing the owner of the item.' });
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });

                                }


                            }
                            else {
                                this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when saving the item.' });
                                this.props.context.hideLoading();
                            }
                        }).catch(error => {
                            this.props.context.pushAlert({ variant: 'danger', message: 'Error occured. Item was not updated.' });
                            console.log(error);
                            this.props.context.hideLoading();
                        });
                } else {
                    axiosData.post(`/v1/${this.state.entry.EventType.Name}/event`, reqData)
                        .then(response => {
                            if (response.data.Result) {
                                this.setState({ dirty: false });
                                this.props.context.pushAlert({ variant: 'success', message: 'Item created.' });
                                if (name === 'SaveClose') {
                                    this.props.history.goBack();
                                }
                                else {
                                    this.setState({ _nodeId: response.data.NodeId });
                                    let limit = 4;
                                    let newGuid = response.data.Result;
                                    this._interval = this.setIntervalImmediately(() => {
                                        limit--;
                                        if (limit >= 0) {
                                            axiosData.post('/old/calls/custom', {
                                                url: `/api/client/AhlstromMunksjo/event?eventtypename=${this.state.entry.EventType.Name}&eventid=${newGuid}&nodeId=${response.data.NodeId}`
                                            })
                                                .then(response => {
                                                    if (response.data.Events.Values.length) {
                                                        clearInterval(this._interval);
                                                        this.props.history.push({ pathname: `/dataentry/form/${params.id}/${newGuid}`, state: { goBackSteps: --goBackSteps } });
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        } else {
                                            clearInterval(this._interval);
                                            this.props.context.hideLoading();
                                        }
                                    }, 5000);
                                }
                            }
                            else {
                                this.props.context.pushAlert({ variant: 'danger', message: 'Error occured. Item was not created.' });
                                this.props.context.hideLoading();
                                //clearInterval(this._interval);
                            }

                        }).catch(error => {
                            this.props.context.pushAlert({ variant: 'danger', message: 'Error occured. Item was not created.' });
                            this.props.context.pushAlert({ variant: 'danger', message: 'Error: ' + error });

                            console.log(error);
                            this.props.context.hideLoading();
                        });
                }
                /*})
                .catch(errors=>{
                    const error = {};
                    errors.inner.forEach(el=>{
                        set(error,el.path, el.message);
                    });
                    this.setState({error:error});
                });
                */
            })
            .catch(errors => {
                const error = {};
                errors.inner.forEach(el => {
                    error[el.path] = el.message;
                });
                this.setState({ error: error });
            })

    }

    handleUnlock = (e) => {
        e.preventDefault();

        if (!window.confirm('Are you sure you want to unlock this entry?')) {
            return;
        }

        this.props.context.showLoading();
        const lockvals = {};
        lockvals['eventid'] = this.state._eventId;
        lockvals['nodeid'] = this.state._nodeId;

        axiosData.post('/old/calls/custompost', {
            url: `eventunlock`,
            values: lockvals
        })
            .then(response => {
                this.props.context.hideLoading();
                if (response.data.Result) {
                    this.setState({ _lockState: 'Unlocked' });
                }
                else {
                    this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when locking the item.' });
                }
            })
            .catch(error => {
                console.log(error);
            });

    }



    onClose = () => {
        if (!this.state.dirty) {
            this.props.history.goBack();
            return;
        }
        if (window.confirm('There are unsaved changes, do you want to leave?')) {
            this.props.history.goBack();
            return;
        }
    }

    deleteEntry = () => {
        if (!window.confirm('Do you really want to delete this entry? This can not be undone.')) {
            return;
        }

        this.props.context.showLoading();

        //const reqData = {};
        //reqData['eventid'] = this.state._eventId;
        //reqData['nodeid'] = this.state._nodeId;


        axiosData.delete(`/v1/${this.state.entry.EventType.Name}/event/${this.state._eventId}`, { headers: { 'Content-Type': 'application/json; charset=utf-8' }, data: { NodeId: this.state._nodeId } })
            .then(response => {
                this.props.context.hideLoading();
                if (response.data != undefined && response.data.Result) {
                    this.setState({
                        entry: undefined,
                        data: {},
                        fields: [],
                        _nodeId: undefined,
                        _itemId: undefined,
                        _lockState: 'Open',
                        _createdBy: '',
                        _ownedBy: '',
                        _eventId: '',
                        dirty: false,
                        error: {},
                        groupsVisibility: {},
                        //visibilityControls: {},
                        goBackSteps: -1,
                        defaultEnumValues: {},
                        enumValues: []
                    });
                    this.props.history.goBack();
                }
                else {
                    this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when deleting the item.' });
                }
            })
            .catch(error => {
                this.props.context.hideLoading();
                console.log(error);
            });
    }



    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevState => {
            const data = { ...prevState.data };
            const error = { ...this.state.error };
            //const groupsVisibility = { ...this.state.groupsVisibility };    //maybe
            data[name] = value;
            error[name] = undefined;


            //debounced actions like visibility
            this.onChangeDebounced(e, data);


            return { data: data, dirty: true, error: error, /*groupsVisibility: groupsVisibility*/ };
        });
    }

    onChangeDebounced = (e, data) => {
        const groupsVisibility = {};    //maybe
        const localEnumValues = JSON.parse(JSON.stringify(this.state.defaultEnumValues));

        // check visibility

        for (const fieldName in this.state.fields) {
            const field = this.state.fields[fieldName];
            if (field.AssociatedGroups) {


                //this is where the magic happens:
                this.setTargetsVisibilities(field, localEnumValues, groupsVisibility, data[fieldName]);


            }
        }
        this.setState({ groupsVisibility: groupsVisibility/*, visibilityControls: visibilityControls*/ });
        this.setState({ enumValues: localEnumValues });
    }

    loadEvent(eventType, guid, nodeId = undefined) {
        this.props.context.showLoading();

        //try to get it from local state (when redirected from create to edit)
        if (nodeId === undefined) {
            nodeId = this.state._nodeId;
        }

        if (nodeId === undefined) {
            if (this.props.history.location.state) {
                nodeId = this.props.history.location.state.passedNodeId;
            }
        }


        axiosData.post('/old/calls/custom', {
            url: `/api/client/AhlstromMunksjo/event?eventtypename=${eventType}&eventid=${guid}${nodeId ? '&nodeId=' + nodeId.toString() : ''}`
        })
            .then(response => {
                if (response.data && response.data.Events && response.data.Events.Values) {
                    const systemColumns = ['_EventID', '_NodeId', '_EventTime', '_StorageTime', '_EventTypeID', '_ParentID', '_ID',
                        '_CreatedBy', '_CreatedAt', '_ModifiedBy', '_ModifiedAt', '_LockedBy', '_LockedAt', '_UnlockedBy', '_UnlockedAt', '_LockState', '_OwnedBy'];
                    const data = { ...this.state.data };
                    //const groupsVisibility = { ...this.state.groupsVisibility };    //maybe
                    const groupsVisibility = {};    //maybe
                    const localEnumValues = JSON.parse(JSON.stringify(this.state.defaultEnumValues));
                    for (const key in response.data.Events.Values[0]) {
                        if (systemColumns.includes(key) || !this.state.fields.hasOwnProperty(key)) {
                            if (key === '_NodeId') {
                                this.setState({ _nodeId: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_ID') {
                                this.setState({ _itemId: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_LockState') {
                                this.setState({ _lockState: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_CreatedBy') {
                                this.setState({ _createdBy: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_OwnedBy') {
                                this.setState({ _ownedBy: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_EventID') {
                                this.setState({ _eventId: response.data.Events.Values[0][key] })
                            }
                            else if (key === '_ParentID') {
                                this.setState({ _parentId: response.data.Events.Values[0][key] })
                            }

                            continue;
                        }

                        const field = this.state.fields[key];
                        if (field.EventField.ValidationFunctionName === 'enum') {
                            let tmpVal = response.data.Events.Values[0][key] ? response.data.Events.Values[0][key].trim() : undefined;
                            data[field.EventField.Name] = (tmpVal === '' || tmpVal === undefined) ? undefined : { label: this.props.context.t(tmpVal), value: tmpVal };
                            //enumValuesLocal[field.EventField.Name] = field.EventField.ValidationFunctionParameter.split(';').filter(el => el.trim() !== '').map(el => ({ label: el.trim(), value: el.trim() }));
                        }
                        else if (isDate(field.EventField.BuiltInType) && response.data.Events.Values[0][key]) {
                            data[key] = new Date(response.data.Events.Values[0][key]);
                        }
                        else {
                            data[key] = response.data.Events.Values[0][key];

                        }


                        //check groups and fields hiding with new values from the response

                        if (field.AssociatedGroups) {
                            //this is where the magic happens:
                            this.setTargetsVisibilities(field, localEnumValues, groupsVisibility, data[key]);




                            //visibilityControls[f.EventField.Name] = [...localVisibilityControls];
                            this.setState({ groupsVisibility: groupsVisibility/*, visibilityControls: visibilityControls*/ });

                        }

                        this.setState({ enumValues: localEnumValues });








                        //if (field.DefaultValue && !response.data.Events.Values[0][key] && this.state.visibilityControls[field.EventField.Name]) {
                        //    this.state.visibilityControls[field.EventField.Name].forEach(el => {
                        //        groupsVisibility[el]--;
                        //    });
                        //} else if (!field.DefaultValue && response.data.Events.Values[0][key] && this.state.visibilityControls[field.EventField.Name]) {
                        //    this.state.visibilityControls[field.EventField.Name].forEach(el => {
                        //        groupsVisibility[el]++;
                        //    });
                        //}
                    }
                    this.setState({ data: data, groupsVisibility: groupsVisibility });

                    axiosUser.get('/rightsforusernameandevent?name=' + this.props.context.user.name + '&nodeId=' + this.state._nodeId + '&eventId=' + this.state._eventId + (this.state._ownedBy ? ("&eventOwner=" + this.state._ownedBy) : ""))
                        .then(response => {
                            const userrights = JSON.parse(response.data);
                            var user = this.props.context.user;
                            user.rights = userrights;
                            localStorage.setItem('user', JSON.stringify(user));
                            this.setState({ user: user });
                        })
                        .catch(error => {
                            console.log(error);
                        });

                }

                if (this.state._parentId !== undefined) {
                    var pl = document.getElementById('parentlink');
                    if (pl !== undefined && pl !== null)
                        this.loadParentLink(this.state._parentId);
                }

                this.props.context.hideLoading();
            })
            .catch(error => {
                this.props.context.pushAlert({ variant: 'danger', message: 'Unable load event.' });
                console.log(error);
                this.props.context.hideLoading();
            });
    }

    loadEntry = (id, guid = undefined, nodeid = undefined) => {
        if (id) {
            this.props.context.showLoading();
            axios.get(`/eventtypepage/${id}`)
                .then(response => {
                    const entry = JSON.parse(response.data);
                    const data = {};
                    const fields = {};

                    const groupsVisibility = {};    //maybe
                    var defaultEnumValues = {};
                    var localEnumValues = [];

                    entry.Groups.forEach(g => {
                        g.EventFieldsGroupEventFields.forEach(f => {
                            if (f.EventField.ValidationFunctionName === 'enum') {
                                defaultEnumValues[f.EventField.Name] = f.EventField.ValidationFunctionParameter.split(';').filter(el => el.trim() !== '').map(el => ({ label: this.props.context.t(el.trim()), value: el.trim() }));
                            }
                        });
                    });
                    this.setState({ defaultEnumValues: defaultEnumValues });
                    localEnumValues = JSON.parse(JSON.stringify(defaultEnumValues));



                    this.props.context.setHeading(this.props.context.t(entry.Name));
                    entry.Groups.forEach(g => {
                        g.InfoText = g.InfoText.trim().length ? g.InfoText.trim() : undefined;
                        g.EventFieldsGroupEventFields.forEach(f => {
                            if (f.Visible) {
                                fields[f.EventField.Name] = f;
                            }

                            if (f.DefaultValue) {
                                if (f.EventField.ValidationFunctionName === 'enum')
                                    data[f.EventField.Name] = { label: this.props.context.t(f.DefaultValue), value: f.DefaultValue };
                                else if (f.DefaultValue === '{{[@Me]}}')
                                    data[f.EventField.Name] = this.props.context.user.name;
                                else if (f.DefaultValue.startsWith('{{') && f.DefaultValue.endsWith('}}')) {
                                    const param = f.DefaultValue.substring(2, f.DefaultValue.length - 2).trim();
                                    const parsed = queryString.parse(this.props.location.search);
                                    data[f.EventField.Name] = parsed[param] ? parsed[param] : undefined;
                                }
                                else
                                    data[f.EventField.Name] = f.DefaultValue;
                            }
                            else {
                                data[f.EventField.Name] = isDate(f.EventField.BuiltInType) ? null : undefined;
                            }

                            if (f.AssociatedGroups) {
                                //multiple values

                                //const localVisibilityControls = {};




                                //this is where the magic happens:
                                this.setTargetsVisibilities(f, localEnumValues, groupsVisibility, f.DefaultValue);




                                //visibilityControls[f.EventField.Name] = [...localVisibilityControls];
                                this.setState({ groupsVisibility: groupsVisibility });
                            }
                            this.setState({ enumValues: localEnumValues });


                        });
                        g.EventFieldsGroupEventFields.sort((a, b) => a.Order > b.Order ? 1 : -1);

                    });
                    entry.Groups.sort((a, b) => a.Order > b.Order ? 1 : -1)
                    this.setState({ entry: entry, data: data, fields: fields }, () => {
                        if (guid) {
                            this.loadEvent(this.state.entry.EventType.Name, guid, nodeid);
                        }
                    });
                    this.props.context.hideLoading();
                })
                .catch(error => {
                    this.props.context.pushAlert({ variant: 'danger', message: 'Unable to load the form.' });
                    console.log(error);
                    this.props.context.hideLoading();
                });
        }
        else
            this.props.context.pushAlert({ variant: 'danger', message: 'Unable to load the form.' });
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.match.url !== this.props.match.url) {
            const { match: { params } } = nextProps;
            this.loadEntry(params.id, params.eventId, params.nodeId);
        }
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        this.loadEntry(params.id, params.eventId, params.nodeId);
    }


    setTargetsVisibilities(f, localEnumValues, groupsVisibility, valueToUse) {
        const localTargetsVisibility = {};

        const lines = f.AssociatedGroups.split('\n').map(el => el.trim());
        lines.forEach(l => {
            var lineValue;
            var lineTargets;
            if (l.indexOf(":") > 0) {
                //value:
                var linesplit = l.split(':').map(el => el.trim());
                lineValue = linesplit[0].trim();
                lineTargets = linesplit[1].split(';').map(el => el.trim());
                lineTargets.forEach(target => {
                    if (valueToUse == lineValue || (valueToUse != undefined && valueToUse.value == lineValue)) { // 1 - equals
                        if (target.startsWith("!")) { // 2 - inverse branch
                            target = target.substring(1);
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, true, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] - 1;
                                else
                                    localTargetsVisibility[target] = 0;
                            }
                        }
                        else { // 2 - regular branch
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, false, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] + 1;
                                else
                                    localTargetsVisibility[target] = 1;
                            }
                        }
                    }
                    else { // 1 - does not equal
                        if (target.startsWith("!")) { // 2 - inverse branch
                            target = target.substring(1);
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, false, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] + 1;
                                else
                                    localTargetsVisibility[target] = 1;
                            }
                        }
                        else { // 2 - regular branch
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, true, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] - 1;
                                else
                                    localTargetsVisibility[target] = 0;
                            }
                        }
                    }
                });
                //localVisibilityControls[lineValue] = lineTargets;
            }
            else {
                //no value (so the below is true for any value)
                lineValue = "###NOVALUE";
                lineTargets = l.split(';').map(el => el.trim());
                lineTargets.forEach(target => {
                    if (valueToUse) { // 1 - value "equals"
                        if (target.startsWith("!")) { // 2 - inverse branch
                            target = target.substring(1);
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, true, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] - 1;
                                else
                                    localTargetsVisibility[target] = 0;
                            }
                        }
                        else { // 2 - regular branch
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, false, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] + 1;
                                else
                                    localTargetsVisibility[target] = 1;
                            }
                        }
                    }
                    else { // 1 - value doesn't "equal"
                        if (target.startsWith("!")) { // 2 - inverse branch
                            target = target.substring(1);
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, false, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] + 1;
                                else
                                    localTargetsVisibility[target] = 1;
                            }
                        }
                        else { // 2 - regular branch
                            if (target.indexOf("(") >= 0) { // 3 - enum
                                var { enumFieldName, filteredValues } = this.getEnumValues(target, true, localEnumValues);
                                localEnumValues[enumFieldName] = [...filteredValues];
                            }
                            else { // 3 - not enum
                                if (localTargetsVisibility.hasOwnProperty(target))
                                    localTargetsVisibility[target] = localTargetsVisibility[target] - 1;
                                else
                                    localTargetsVisibility[target] = 0;
                            }
                        }
                    }
                });
                //localVisibilityControls[lineValue] = lineTargets;
            }
        });

        for (var target in localTargetsVisibility) {

            if (localTargetsVisibility[target] > 0) {
                if (groupsVisibility.hasOwnProperty(target))
                    groupsVisibility[target] = groupsVisibility[target] + 1;
                else
                    groupsVisibility[target] = 1
            } else {
                if (groupsVisibility.hasOwnProperty(target))
                    groupsVisibility[target] = groupsVisibility[target] - 1;
                else
                    groupsVisibility[target] = 0
            }
            if (groupsVisibility[target] < 0)
                groupsVisibility[target] = 0;
        }

    }

    getEnumValues(target, isThisInverted, localEnumValues) {
        var enumsplit = target.split('(');
        var enumFieldName = enumsplit[0];
        var enumvalues = enumsplit[1].replace(")", "").split(",").filter(el => el.trim() !== '').map(el => ({ label: this.props.context.t(el.trim()), value: el.trim() }));
        var filteredValues = {};
        if (isThisInverted) {
            //filters out only the exclusion
            filteredValues = localEnumValues[enumFieldName] !== undefined ? localEnumValues[enumFieldName].filter(el => !enumvalues.map(a => a.value).includes(el.value)) : [];
        }
        else {
            //filters out only the intersection
            filteredValues = localEnumValues[enumFieldName] !== undefined ? localEnumValues[enumFieldName].filter(el => enumvalues.map(a => a.value).includes(el.value)) : [];
        }
        return { enumFieldName, filteredValues };
    }


    shareEntry = (e) => {
        e.preventDefault();

        this.props.context.showLoading();
        const lockvals = {};
        lockvals['eventid'] = this.state._eventId;
        lockvals['nodeid'] = this.state._nodeId;

        axiosData.post('/old/calls/custompost', {
            url: `eventunlock`,
            values: lockvals
        })
            .then(response => {
                this.props.context.hideLoading();
                if (response.data.Result) {
                    this.setState({ _lockState: 'Unlocked' });
                }
                else {
                    this.props.context.pushAlert({ variant: 'danger', message: 'Error occured when locking the item.' });
                }
            })
            .catch(error => {
                console.log(error);
            });

    }



    getOwners = (query) => {
        return axiosUser.get('/allavailableowners', { params: { name: query } }).then(response => {
            if (response && response.data) {
                const tmp = JSON.parse(response.data).map((el, index) => ({ value: el, label: el }));
                return tmp;
            }
            return [];
        }).catch(error => console.log(error));
    }


    setNewOwner = (e) => {
        var val = e.target.value;
        this.setState({ _newOwner: val.value });
    }

    removeParentLink = () => {
        var pl = document.getElementById('parentlink');
        if (pl !== undefined && pl !== null)
            pl.remove();
    }

    loadParentLink = (parentId) => {
        if (parentId === undefined || typeof parentId !== 'string') {
            //this shouldn't happen, but just in case
            this.removeParentLink();
            return;
        }

        var eventtypeid = parentId.split('_')[0];

        //get parent form id first
        axios.get(`/EventTypePage/GetForEventType/${eventtypeid}`)
            .then(response => {
                const parentFormId = JSON.parse(response.data);

                //try a data call for parent record, so we know if we can see it
                axiosData.post('/old/calls/custom', {
                    url: `/api/client/AhlstromMunksjo/event?eventtypeid=${eventtypeid}&eventid=${parentId}`
                })
                    .then(response => {
                        if (response.data && response.data.Events && response.data.Events.Values) {
                            const parentNodeId = response.data.Events.Values[0]['_NodeId'];

                            if (parentNodeId !== undefined) {
                                //if all went well, show the correct link
                                var pl = document.getElementById('parentlink');
                                if (pl !== undefined && pl !== null) {
                                    pl.setAttribute('href', `/dataentry/form/${parentFormId}/${parentId}/${parentNodeId}`);
                                    pl.textContent = 'Link to parent record';
                                }
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.removeParentLink();
                    });
            })
            .catch(error => {
                console.log(error);
                this.removeParentLink();
            });
    }


    render() {
        const { match: { params } } = this.props;
        var localParentId = params.parentId;
        if (localParentId === undefined || localParentId === null || localParentId === '')
            localParentId = this.state._parentId;
        if (this.state.entry)
            return (
                <React.Fragment>

                    <span style={{ float: 'right' }}>
                        {params.eventId && localParentId && params.parentFormId ? (
                            //if parent id is set show link to parent
                            //if parent id is set so should be parent node id
                            <>
                                <a href={`/dataentry/form/${params.parentFormId}/${localParentId}/${params.parentNodeId}`}>Link to parent record</a>
                            </>
                        )
                            :
                            params.eventId && localParentId ? (

                                //only parentId is set up - we need to figure out the parentFormId and parentNodeId
                                //but we do that in a separate api call - now we only show an inactive link
                                <>
                                    <a id='parentlink' href='#void' >Link to parent record (fetching)</a>
                                </>

                            )
                                :
                                null}
                    </span>

                    <h3>{this.state._itemId !== undefined ? (this.props.context.t(this.state.entry.Name) + " #" + this.state._itemId) : this.props.context.t(this.state.entry.Name)} {this.state._lockState === "Locked" ? (this.props.context.t("[Locked]")) : ("")}  </h3>

                    <form>

                        <fieldset {...(((this.state._lockState === "Locked") || (this.state._itemId !== undefined && this.props.context.user.rights !== undefined && this.props.context.user.rights.Update === false)) ? { disabled: "disabled" } : {})}>

                            {this.state.entry.Groups.map((g, i) => (
                                <Group context={this.props.context} group={g} key={i} value={this.state.data} enumValues={this.state.enumValues} onChange={this.onChange} error={this.state.error} closed={g.Collapsed}
                                    visible={this.state.groupsVisibility[g.Name]} allVisibility={this.state.groupsVisibility} />
                            ))}

                        </fieldset>

                        <ButtonGroup>
                            <Button onClick={this.onClose} variant="warning">{this.props.context.t("Exit")}</Button>

                            {this.state._lockState === "Locked" ? (
                                (this.props.context.user.rights !== undefined && this.props.context.user.rights.Unlock) ? (
                                    <Button onClick={this.handleUnlock} name="Unlock" variant="primary">{this.props.context.t("Unlock")}</Button>
                                ) : (
                                    <Button variant="dark" disabled="disabled">{this.props.context.t("Entry is locked")}</Button>
                                )

                            ) : (
                                <>
                                    <Button onClick={this.handleSubmit} name="Save" variant="primary">{this.props.context.t("Save")}</Button>
                                    <Button onClick={this.handleSubmit} name="SaveClose" variant="success">{this.props.context.t("Save & Exit")}</Button>
                                    {(this.state._itemId && (this.props.context.user.rights !== undefined && this.props.context.user.rights.Lock)) ? (
                                        <Button onClick={this.handleSubmit} name="SaveLock" variant="primary">{this.props.context.t("Save & Lock")}</Button>
                                    ) : ""
                                    }
                                </>
                            )}

                            {(this.state._itemId && this.props.context.user && this.props.context.user.rights !== undefined && this.props.context.user.rights.Delete) ? (
                                //delete button
                                <Button onClick={this.deleteEntry} variant="danger">{this.props.context.t("Delete")}</Button>
                            ) : null}

                            {(this.state._itemId && this.props.context.user && this.props.context.user.rights !== undefined && this.props.context.user.rights.Share && this.props.context.user.rights.Update) ? (
                                //change owner (based on share) button
                                <Button onClick={this.modalShow} variant="info">{this.props.context.t("Save & Change owner")}</Button>
                            ) : null}

                        </ButtonGroup>

                    </form>




                    {
                        this.state.entry.AllowAssociatedEvents && params.eventId && this.state._nodeId ? (
                            <React.Fragment>
                                <hr />
                                {this.state.entry.AssociatedEventDataViews.map((view, i) => (
                                    <View context={this.props.context} value={view} eventType={this.state.entry.EventType} key={i} parentId={params.eventId} nodeId={this.state._nodeId} parentFormId={params.id} />
                                ))}
                            </React.Fragment>
                        ) : null
                    }
                    {
                        !this.state.entry.DisableAttachments && params.eventId && this.state._nodeId ? (
                            <>
                                <hr />
                                <Attachments key={params.eventId} Title={this.props.context.t("Attachments")} eventId={params.eventId} eventtypename={this.state.entry.EventType.Name} />
                            </>
                        ) : null
                    }
                    {
                        params.eventId && this.state._nodeId ? (
                            <>
                                <hr />
                                <OfficeExports key={params.eventId} Title={this.props.context.t("Office Exports")} eventId={params.eventId} nodeId={this.state._nodeId} />
                            </>
                        ) : null
                    }

                    <Modal show={this.state.show} onHide={this.modalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change owner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <BootstrapInput onChange={this.setNewOwner} name="NewOwner" label="New owner: " /*onChange={this.loadET}*/ loadOptions={this.getOwners} defaultOptions /*isSearchable={false}*/ /*error={error} value={this.state.data.EventType}*/ component={AsyncSelect} />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.modalClose}>
                                Close
                            </Button>
                            <Button name="ChangeOwner" variant="primary" onClick={this.handleSubmit}>
                                Save new owner
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </React.Fragment >

            );
        else
            return null;
    }



    setIntervalImmediately(func, interval) {
        func();
        return setInterval(func, interval);
    }
}

export default withContext(Entry);