import AuthenticationContext from 'adal-angular';
import { OktaAuth } from '@okta/okta-auth-js';




/**
 * Configuration of Azure login. See Adal.js
 */
//export const AdalConfig = {
//    tenant: process.env.REACT_APP_TENANT,
//    clientId: process.env.REACT_APP_CLIENT_ID,
//    endpoints: {
//        api: process.env.REACT_APP_CLIENT_ID,
//    },
//    cacheLocation: 'localStorage',
//    postLogoutRedirectUri: window.location.origin,
//    redirectUri: window.location.origin
//};


//const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
//const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = '/implicit/callback';
const HOST = window.location.host;
const REDIRECT_URI = `https://${HOST}${CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

var OktaConfig = {};
export var AdalConfig = {};

//const OktaConfig = {
//    issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
//    clientId: CLIENT_ID,
//    redirectUri: REDIRECT_URI,
//    scopes: SCOPES.split(/\s+/),
//};


window.Logging.log = function (message) {
    console.log(message); // this enables logging to the console
}
window.Logging.level = 2;

export var AdalAuthObject = undefined;

export var OktaAuthObject = undefined;

//export const getToken = () => {
//    return AdalAuthObject.getCachedToken(AdalAuthObject.config.clientId);
//};

export const setOktaConfig = (provider) => {
    OktaConfig = {
        issuer: `https://${provider.TenantDomain}`,//oauth2/default`,
        clientId: provider.ClientId,
        redirectUri: REDIRECT_URI,
        scopes: SCOPES.split(/\s+/),
    };
    OktaAuthObject = new OktaAuth(OktaConfig)
}

export const setAadConfig = (provider) => {
    AdalConfig = {
        tenant: provider.TenantId,
        clientId: provider.ClientId,
        endpoints: {
            api: provider.ClientId,
        },
        cacheLocation: 'localStorage',
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: false
    };
    AdalAuthObject = new AuthenticationContext(AdalConfig);
}

//export const getOktaAuthObject = () => {
//    return OktaAuthObject;
//}

const setOktaConfigFromLocalstorage = () => {
    var p1 = localStorage.getItem("oktaProvider");
    if (p1 !== undefined && p1 != null) {
        var p = JSON.parse(p1);
        setOktaConfig(p);
    }
}

setOktaConfigFromLocalstorage();

const setAadConfigFromLocalstorage = () => {
    var p1 = localStorage.getItem("aadProvider");
    if (p1 !== undefined && p1 != null) {
        var p = JSON.parse(p1);
        setAadConfig(p);
    }
}

setAadConfigFromLocalstorage();