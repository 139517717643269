import React from 'react';
import {Alert} from 'react-bootstrap';

/*
    Component for showing error/success messages on top of application.
    Props:
    - dismissable: show cross on top right corner to close message
    - variant: same as bootstraps variant - changes color settings (default, success, error, warning etc.)
    - onRemove: on close callback
    - closeLabel: text for tooltip hint
    - show: boolean to show/hide alert
    - heading: Bold heading of alert
    - message: text of alert
*/

export default props => (
    <Alert dismissible variant={props.variant} onClose={props.onRemove} closeLabel="Close" show>
        {props.heading ? (
            <Alert.Heading>
                {props.heading}
            </Alert.Heading>
        ): null}
        <p>
            {props.message}
        </p>
    </Alert>
);
