import React from 'react';
import * as yup from 'yup';
import axios from '../../helpers/axiosWebEntry';
import axiosUser from '../../helpers/axiosUser';
import AsyncSelect from '../../components/Select/AsyncSelect';
import { Button, ButtonGroup } from 'react-bootstrap';
import withContext from '../../hoc/withContext';
import BootstrapInput from '../../components/FormInput/BootstrapInput2';

const schema = yup.object().shape({
    Title: yup.string().required('Required.'),
    Order: yup.number().integer('Must be a integer.').required('Required'),
    Roles: yup.array().required('Required.'),
    //Link: yup.string().required('Required.'),
    Image: yup.string().required('Required.'),
    Parent: yup.mixed().required('Required.')
});

class Form extends React.Component {
    state = {
        data: {
            ID: '',
            Title: undefined,
            Description: undefined,
            Order: 0,
            Roles: [],
            Link: undefined,
            Image: undefined,
            Parent: undefined
        },
        error: {},
        dirty: false,
    }

    componentDidMount = () => {
        const { match: { params } } = this.props;
        this.props.context.showLoading();

        if (!params.id) {
            this.setState(() => ({
                data: {
                    Title: undefined,
                    Description: undefined,
                    Order: params.order ? params.order : 0,
                    Roles: [],
                    Link: undefined,
                    Image: undefined,
                    Parent: undefined
                },
                error: {},
                dirty: false,
            }), () => {
                if (!params.parent)
                    return;
                axios.get(`/landingtile/${params.parent}`).then(response => {
                    if (response && response.data) {
                        const parent = JSON.parse(response.data);
                        const data = { ...this.state.data };
                        data.Parent = { value: parent.ID, label: parent.Title }
                        this.setState({ data: data });
                    }
                }).catch(error => console.log(error));
            });

            this.props.context.hideLoading();
        } else {
            axios.get(`/landingtile/${params.id}`)
                .then(response => {
                    const data = JSON.parse(response.data);
                    data.Roles = data.Roles.map(el => ({ value: el.IdentityRole.Id, label: el.IdentityRole.Name }));
                    data.Parent = data.Parent ? { value: data.Parent.ID, label: data.Parent.Title } : undefined;
                    this.setState({ data: data });
                    this.props.context.hideLoading();
                })
                .catch(error => {
                    console.log(error);
                    this.props.context.hideLoading();
                });
        }
    }

    getTiles = (query) => {
        return axios.get('/landingtile', { params: { name: query } }).then(response => {
            if (response && response.data) {
                const tmp = response.data.map((el, index) => ({ value: el.id, label: el.title }));
                return tmp;
            }
            return [];
        }).catch(error => console.log(error));
    }

    getRoles = (query) => {
        return axiosUser.get('/roles', { params: { name: query } }).then(response => {
            if (response && response.data) {
                const tmp = JSON.parse(response.data).map((el, index) => ({ value: el.Id, label: el.Name }));
                return tmp;
            }
            return [];
        }).catch(error => console.log(error));
    }

    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const data = { ...this.state.data };
        const error = { ...this.state.error }
        data[name] = value;
        error[name] = undefined;
        this.setState({ data: data, error: error, dirty: true });
    }

    onClose = () => {
        if (!this.state.dirty) {
            this.props.history.goBack();
            return;
        }
        if (window.confirm('There are unsaved changes, do you want to leave?')) {
            this.props.history.goBack();
            return;
        }
    }

    handleSubmit = (e) => {
        const name = e.target.name;
        e.preventDefault();
        schema.validate(this.state.data, { abortEarly: false }).then(values => {
            this.props.context.showLoading();
            const { match: { params } } = this.props;
            const data = { ...this.state.data };
            data.ParentId = data.Parent.value;
            data.Roles = data.Roles.map(item => ({ IdentityRoleId: item.value }));

            if (params && params.id) {
                axios.put(`/landingtile/${data.ID}`, data)
                    .then(response => {
                        this.setState({ dirty: false });
                        this.props.context.pushAlert({ variant: 'success', message: 'Item updated.' });
                        this.props.context.hideLoading();
                        if (name === 'SaveClose')
                            this.props.history.goBack();
                    }).catch(error => {
                        this.props.context.pushAlert({ variant: 'danger', message: 'Error occured. Item was not updated.' });
                        console.log(error);
                        this.props.context.hideLoading();
                    });
            } else {
                axios.post(`/landingtile`, data)
                    .then(response => {
                        this.setState({ dirty: false });
                        this.props.context.pushAlert({ variant: 'success', message: 'Item created.' });
                        this.props.context.hideLoading();
                        if (name === 'SaveClose')
                            this.props.history.goBack();
                        else
                            this.props.history.push(`/landingtile/update/${response.data.id}`);
                    }).catch(error => {
                        this.props.context.pushAlert({ variant: 'danger', message: 'Error occured. Item was not created.' });
                        console.log(error);
                        this.props.context.hideLoading();
                    });
            }
        }).catch(errors => {
            console.log(errors);
            const error = {};
            errors.inner.forEach(el => {
                error[el.path] = el.message;
            });
            this.setState({ error: error });
        });

    }


    render() {
        const error = this.state.error;
        return (
            <div className="content-overlay">
                <form>
                    <fieldset>
                        <legend>Landing Tile Settings</legend>
                        <input name="ID" type="hidden" value={this.state.data.ID} />
                        <BootstrapInput name="Title" label="Title" value={this.state.data.Title} error={error} onChange={this.onChange} />
                        <BootstrapInput name="Description" label="Description" value={this.state.data.Description} error={error} onChange={this.onChange} />
                        <BootstrapInput name="Link" label="Link" value={this.state.data.Link} error={error} onChange={this.onChange} info="Leave empty for opening child tiles." />
                        <BootstrapInput name="Image" label="Image" value={this.state.data.Image} error={error} onChange={this.onChange} info="Absolute Image URL or start with / to load from Blob Storage." />
                        <BootstrapInput name="Roles" label="Available for Roles" onChange={this.onChange} defaultOptions error={error} value={this.state.data.Roles} isMulti={true} loadOptions={this.getRoles} component={AsyncSelect} />
                        <BootstrapInput name="Parent" label="Parent" value={this.state.data.Parent} error={error} onChange={this.onChange} component={AsyncSelect} defaultOptions loadOptions={this.getTiles} />
                        <BootstrapInput name="Order" label="Order" type="number" value={this.state.data.Order} error={error} onChange={this.onChange} />
                    </fieldset>
                    <ButtonGroup>
                        <Button onClick={this.onClose} variant="warning">Exit</Button>
                        <Button onClick={this.handleSubmit} name="Save" variant="primary">Save</Button>
                        <Button onClick={this.handleSubmit} name="SaveClose" variant="success">Save &amp; Exit</Button>
                    </ButtonGroup>
                </form>
            </div>
        );
    }
}

export default withContext(Form);