import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { isUrlAbsolute } from '../../helpers/utils';

/**
 * Component of landing page card.
 * Props:
 * - allowEdit: display admin icons on tile
 * - disableRemoving: hide remove icon, used on root or add tiles
 * - onRemoveTile: callback called on removing tile from parent
 * - image: tile image
 * - title: tile title
 * - description: tile description text
 * - ID: tile id (from db)
 * - link: tile link; tile redirects on link; if not provided it will open page with child tiles
 */
export default withRouter(props => {
    const component = (
        <Card className="landing-tile">
            {props.allowEdit ? (
                <div className="tile-header">
                    <FontAwesomeIcon className="ml-2" icon="pen" onClick={(e) => {
                        e.preventDefault();
                        props.history.push(`/landingtile/update/${props.ID}`);
                    }} />

                    {!props.DisableRemoving ?
                        <FontAwesomeIcon className="ml-2" icon="times" onClick={(e) => {
                            e.preventDefault();
                            props.onRemoveTile(props.ID);
                        }} />
                        : null
                    }
                </div>
            ) : null}
            <Card.Img variant="top" src={props.Image} />
            <Card.Body className="tile-body">
                <Card.Title className="tile-title">{props.context.t(props.Title)}</Card.Title>
                <Card.Text className="tile-description">
                    {props.context.t(props.Description)}
                </Card.Text>
            </Card.Body>
        </Card>
    );

    return (
        <React.Fragment>
            {isUrlAbsolute(props.Link) ?
                (
                    <a href={props.Link}>
                        {component}
                    </a>
                ) :
                (
                    <Link to={props.Link ? props.Link : `/landingtile/${props.ID}`}>
                        {component}
                    </Link>
                )
            }
        </React.Fragment>
    );
});