import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Form from './Form';
import {Container} from 'react-bootstrap';
import Landing from './Landing';

export default props => (
    <Container>
        <Switch>
            <Route path="/landingtile/create/:parent?/:order?" component={Form}/>
            <Route path="/landingtile/update/:id" component={Form}/>
            <Route path="/landingtile/:id?" component={Landing}/>
            <Route path="/" component={Landing}/>
        </Switch>
    </Container>
);

