import React from 'react';
import axios from '../helpers/axiosUser';
import { withRouter } from 'react-router-dom';
import * as JWT from 'jwt-decode';
import i18n from '../translations/i18n';
import { withOktaAuth } from '@okta/okta-react';


const AuthContext = React.createContext();

/**
 * Global state using React Context API.
 * Manages:
 * user login/logout
 * display of loading spinner over whole screen
 * storage of error/success alerts
 * setting of navigation header
 */
class _AuthProvider extends React.Component {
    /**
     * Get user from localstorage. JWT token contains all info about user.
     * ems.idtoken is our database based JWT token
     * adal.idtoken is Azure JWT token
     */
    getUserFromLocal = () => {
        let token = undefined;
        if (localStorage.getItem('ems.idtoken'))
            token = localStorage.getItem('ems.idtoken');
        else if (localStorage.getItem('adal.idtoken')) {
            token = localStorage.getItem('adal.idtoken');
        }
        else if (localStorage.getItem('okta-token-storage')) {
            let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
            if (oktaStorage !== undefined && oktaStorage.idToken !== undefined && oktaStorage.idToken.value !== undefined) {
                token = oktaStorage.idToken.value;
            }
        }

        //todo check expiry date
        //if not delete from local storage
        else
            return undefined;

        let jwttoken = JWT(token);
        let seconds = Math.round((new Date()).getTime() / 1000);

        if (jwttoken.exp < seconds) {
            localStorage.removeItem('ems.idtoken')
            localStorage.removeItem('adal.idtoken')
            localStorage.removeItem('okta-token-storage')
            return undefined;
        }

        return jwttoken;
    }

    state = {
        //show global loading spinner
        isLoading: false,

        isMessageHidden: true,
        //array of alert messages
        alerts: [],
        //global navigation header
        heading: '',
        //currently singed user object parsed from JWT token
        user: this.getUserFromLocal(),
    };


    /**
     * Initial ops. Load user from storage, parse it and store it to state.
     * If JWT is from Azure, load roles from our server.
     */
    componentDidMount = () => {

        let userFromJwt = undefined;
        let token = undefined;
        if (localStorage.getItem('ems.idtoken'))
            token = localStorage.getItem('ems.idtoken');
        else if (localStorage.getItem('adal.idtoken')) {
            token = localStorage.getItem('adal.idtoken');
        }
        else if (localStorage.getItem('okta-token-storage')) {
            let oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
            if (oktaStorage !== undefined && oktaStorage.idToken !== undefined && oktaStorage.idToken.value !== undefined) {
                token = oktaStorage.idToken.value;
            }
        }

        else
            return;
        userFromJwt = JWT(token);
        let user = { name: userFromJwt.unique_name, token: token, roles: userFromJwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'], canLock: userFromJwt.canLock === "True" ? true : false, canUnlock: userFromJwt.canUnlock === "True" ? true : false };

        if (user.name === undefined)
            user.name = userFromJwt.email;

        localStorage.setItem('user', JSON.stringify(user));
        this.hideRolesMessage();
        this.setState({ user: user }, () => {
            if (!user.roles) {


                axios.get('/userroles')
                    .then(response => {
                        const roles = JSON.parse(response.data);
                        user.roles = roles;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.setState({ user: user }, () => {
                            if (!user.roles || user.roles.length == 0) {
                                this.showRolesMessage();
                            }
                        });

                    })
                    .catch(error => {
                        console.log(error);
                        this.showRolesMessage();
                    });

                axios.get('/usercanlock?name=' + user.name)
                    .then(response => {
                        const can = JSON.parse(response.data);
                        user.canLock = can;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.setState({ user: user });
                    })
                    .catch(error => {
                        console.log(error);
                    });

                axios.get('/usercanunlock?name=' + user.name)
                    .then(response => {
                        const can = JSON.parse(response.data);
                        user.canUnlock = can;
                        localStorage.setItem('user', JSON.stringify(user));
                        this.setState({ user: user });
                    })
                    .catch(error => {
                        console.log(error);
                    });


            }
        });
    }

    /**
     * Login ajax call for non-azure login. It stores parsed JWT token user to state.
     */
    login = (email, pass) => {
        this.hideRolesMessage();
        this.showLoading();
        axios.post('/authenticate', { email: email, password: pass })
            .then(response => {
                const token = response.data;
                let user = JWT(token);
                user = { name: user.unique_name, token: token, roles: user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] };
                localStorage.clear();
                localStorage.setItem('ems.idtoken', token);
                localStorage.setItem('user', JSON.stringify(user));
                this.setState({ user: user }, () => {

                    axios.get('/usercanlock?name=' + user.name)
                        .then(response => {
                            const can = JSON.parse(response.data);
                            user.canLock = can;

                            axios.get('/usercanunlock?name=' + user.name)
                                .then(response => {
                                    const canu = JSON.parse(response.data);
                                    user.canUnlock = canu;


                                    localStorage.setItem('user', JSON.stringify(user));
                                    this.setState({ user: user }, () => {
                                        if (!user.roles || user.roles.length == 0) {
                                            this.showRolesMessage();
                                        }
                                    });
                                    this.pushAlert({ variant: 'success', message: 'Logged In.' });

                                })
                                .catch(error => {
                                    console.log(error);
                                });

                        })
                        .catch(error => {
                            console.log(error);
                        });
                });

            })
            .catch(error => {
                this.pushAlert({ variant: 'danger', message: (error && error.response && error.response.data && error.response.data.message) ? error.response.data.message : 'Unable to login.' })
                console.log(error);
                this.hideLoading();
            });
    }

    /**
     * Reset password call.
     */
    reset = (email, oldPass, newPass) => {
        this.showLoading();
        axios.post('/resetpassword', { Email: email, OldPassword: oldPass, NewPassword: newPass })
            .then(response => {
                this.pushAlert({ variant: 'success', message: 'Password successfully changed. Please log in with new password.' });
                this.logout();
                this.hideLoading();
            })
            .catch(error => {
                this.pushAlert({ variant: 'danger', message: error.response.data.message ? error.response.data.message : 'Unable to reset password.' })
                console.log(error);
                this.hideLoading();
            });
    }

    /**
     * Logout call. Clears whole localstorage.
     */
    logout = () => {
        this.setState({ user: undefined });
        localStorage.clear();
        this.props.history.push('/login')
    }

    /**
     * Adds new alert message to state.
     */
    pushAlert = (alert) => {
        const alerts = [...this.state.alerts];
        alerts.push(alert);
        this.setState({ alerts: alerts });
    }

    /**
     * Removes alert message based on index.
     */
    removeAlert = (index) => {
        const alerts = [...this.state.alerts];
        alerts.splice(index, 1);
        this.setState({ alerts: alerts });
    }

    /**
     * Removes all alerts.
     */
    clearAlerts = () => {
        this.setState({ alerts: [] });
    }

    /**
     * Shows global loading spinner.
     */
    showLoading = () => {
        this.setState({ isLoading: true });
    }

    hideRolesMessage = () => {
        this.setState({ isMessageHidden: true });
    }

    showRolesMessage = () => {
        this.setState({ isMessageHidden: false });
    }

    /**
     * Hides global loading spinner.
     */
    hideLoading = () => {
        this.setState({ isLoading: false });
    }

    /**
     * Changes state and sets global navigation header.
     */
    setHeading = (heading) => {
        this.setState({ heading: heading });
    }

    /**
     * Check if current user is admin.
     */
    isAdmin = () => {
        if (this.state.user && this.state.user.roles)
            return this.state.user.roles.includes('Admin');
        return false;
    }

    t = (key) => { return i18n.t(key); }

    triggerComponentDidMountToSaveUser = () => {
         this.componentDidMount()
    }


    setLanguage = (lang) => {
        i18n.changeLanguage(lang);
    }

    render() {
        let lg = this.state.user !== undefined ? this.state.user.language : undefined;
        if (lg === undefined) {
            lg = navigator.language || navigator.userLanguage;
        }
        i18n.changeLanguage(lg)

        return (
            <AuthContext.Provider value={{
                //vars and methos provided by global state
                user: this.state.user,
                alerts: this.state.alerts,
                heading: this.state.heading,
                isLoading: this.state.isLoading,
                isMessageHidden: this.state.isMessageHidden,
                showLoading: this.showLoading,
                hideLoading: this.hideLoading,
                login: this.login,
                reset: this.reset,
                logout: this.logout,
                pushAlert: this.pushAlert,
                removeAlert: this.removeAlert,
                clearAlerts: this.clearAlerts,
                setHeading: this.setHeading,
                isAdmin: this.isAdmin,
                hideRolesMessage: this.hideRolesMessage,
                showRolesMessage: this.showRolesMessage,
                t: this.t,
                setLanguage: this.setLanguage,
                triggerComponentDidMountToSaveUser: this.triggerComponentDidMountToSaveUser,
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthConsumer = AuthContext.Consumer;
const AuthProvider = /*withOktaAuth(*/withRouter(_AuthProvider)/*)*/;

export { AuthProvider, AuthConsumer, AuthContext }